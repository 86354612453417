import axios from "axios";
import { useContext, useState } from "react";
import { IsfpProcess } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/params";
import { AuthContext } from "../Contexts";

export const API_ENDPOINT = `${apiEndpoint()}/isfps`;

export function useFinalizeIsfpProcess() {
  const { token } = useContext(AuthContext);
  const [updatedIsfpProcess, setUpdatedIsfpProcess] = useState<IsfpProcess>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [finalizeError, setFinalizeError] = useState();

  const finalizeIsfpProcess = async (file: File, isfpProcessId: string) => {
    setIsLoading(true);
    setFinalizeError(undefined);

    try {
      const [_, extension] = file.name.split(".");
      const requestUri = `${API_ENDPOINT}/presigned-file-link`;
      const response = await axios.post(
        requestUri,
        { extension },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token?.id_token}`,
          },
        },
      );
      const { presignedLink, filename } = response.data;
      const uploadPhotoResponse = await axios.put(presignedLink, file, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
          maxBodyLength: Infinity,
          maxContentLength: Infinity,
          responseType: "json",
        },
      });
      console.log(uploadPhotoResponse);

      const finalizeIsfpProcessResponse = await axios.post(`${API_ENDPOINT}/${isfpProcessId}/finalize`, JSON.stringify({ filename }), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.id_token}`,
        },
      });

      setUpdatedIsfpProcess(finalizeIsfpProcessResponse.data);
    } catch (e) {
      console.log(e);
      setFinalizeError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setFinalizeError(undefined);
    setIsLoading(false);
  };

  return { finalizeIsfpProcess, isLoading, updatedIsfpProcess, finalizeError, resetState };
}

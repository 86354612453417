import AssessmentIcon from "@mui/icons-material/Assessment";
import BoltIcon from "@mui/icons-material/Bolt";
import CloseIcon from "@mui/icons-material/Close";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import HelpIcon from "@mui/icons-material/Help";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  Skeleton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import NovoCustomSelect from "../../../components/common/NovoCustomSelect";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import useFetchInviteEmailTemplate from "../../../hooks/useFetchInviteEmailTemplate";
import { leadErrorCodeToMessage, useCreateLead } from "../../../hooks/useLeadInviteHooks";
import { InviteEmailTemplate, Product } from "../../../types/cockpit/types";
import { isValidEmail } from "../../auth/emailValidator";
import { CCInviteClarification } from "./leadlist/CCInviteClarification";
import Voucher from "./Voucher";

interface InviteNewLeadDialogProps {
  onClose: () => void;
  open: boolean;
}

export default function InviteNewLeadDialog({ open, onClose }: InviteNewLeadDialogProps) {
  // const { user } = useContext(AuthContext);
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState("");
  // const [selfInvite, setSelfInvite] = useState(false);
  const [cc, setCc] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [product, setProduct] = useState("");
  const [productError, setProductError] = useState(false);
  const [free, setFree] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { createLead, error, isLoading, lead } = useCreateLead();
  const { getInviteEmailTemplate, template: fetchedTemplate } = useFetchInviteEmailTemplate();
  const [template, setTemplate] = useState<InviteEmailTemplate | undefined>();

  const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccess(false);
    setCc(!!event.target.checked);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccess(false);
    setErrorMessage("");
    setNameError(false);
    setName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccess(false);
    setErrorMessage("");
    setEmailError(false);
    setEmail(event.target.value.toLowerCase());
  };

  const handleProductChange = (value: string) => {
    setFree(false);
    setShowSuccess(false);
    setErrorMessage("");
    setProductError(false);
    setProduct(value);
  };

  // const selfInviteChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setEmail(user?.email ?? "");
  //     setName("Max Mustermann");
  //     setProduct("Schnellcheck");
  //     setSelfInvite(true);
  //     setFree(false);
  //   } else {
  //     setEmail("");
  //     setName("");
  //     setProduct("");
  //     setSelfInvite(false);
  //   }
  //   setShowSuccess(false);
  //   setErrorMessage("");
  // };

  const handleFreeChange = (checked: boolean) => {
    setShowSuccess(false);
    setErrorMessage("");
    setFree(checked);
  };

  const handleClose = () => {
    setName("");
    setNameError(false);
    setEmail("");
    setEmailError(false);
    setProduct("");
    setProductError(false);
    setFree(false);
    setShowSuccess(false);
    setErrorMessage("");
    onClose();
  };

  const handleSubmit = () => {
    if (!name) {
      setNameError(true);
      return;
    }
    if (!email || !isValidEmail(email)) {
      setEmailError(true);
      return;
    }
    if (!product) {
      setProductError(true);
      return;
    }
    const reqBody = {
      name,
      email,
      referrerProduct: {
        product: Product[productMapping.get(product)!],
        isFree: free,
      },
      cc,
    };
    setShowSuccess(false);
    setErrorMessage("");
    createLead(reqBody);
  };

  const productMapping = new Map<string, Product>();
  productMapping.set("Schnellcheck", Product.isfp);
  productMapping.set("Energieausweis", Product.consumptionCertificate);
  productMapping.set("Sanierungsgutachten", Product.onePager);

  const productOptions = [
    { value: "Schnellcheck", subTitle: "Kostenloser erster Schritt für die iSFP-Erstellung & Expertenberatung", icon: <EnergySavingsLeafIcon /> },
    {
      value: "Energieausweis",
      subTitle: "Offizieller Energieausweis auf Basis von Energieverbrauch oder -bedarf",
      icon: <BoltIcon />,
    },
    { value: "Sanierungsgutachten", subTitle: "Schätzung der Sanierungskosten für Kauf oder Anschlussfinanzierung", icon: <AssessmentIcon /> },
  ];

  useEffect(() => {
    const selectedProduct = product ? Product[productMapping.get(product)!] : undefined;
    getInviteEmailTemplate(selectedProduct, free);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [free, getInviteEmailTemplate, product]);

  useEffect(() => {
    if (error) {
      setErrorMessage(leadErrorCodeToMessage(error.response?.data?.code));
    } else if (lead) {
      setShowSuccess(true);
      setName("");
      setEmail("");
      setProduct("");
    }
  }, [lead, error]);

  useEffect(() => {
    if (fetchedTemplate) {
      setTemplate(fetchedTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTemplate]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <Box data-joyride='lead-invite-modal' data-cy='invite-lead-modal' sx={{ p: 2 }}>
        <DialogTitle>
          Neuen Kunden einladen
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose} data-joyride='lead-invite-modal-close' data-cy='invite-lead-cancel-btn'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={6}>
              <Stack spacing={2}>
                <DialogContentText>
                  Bitte gib den Namen und die E-mail-Adresse des Kunden sowie das Produkt an, das du ihm empfehlen möchtest.
                </DialogContentText>
                {/* Commented out for the moment, as consultants were inviting themselves but landed in bubble as consultants, not homeowners */}
                {/* <Tooltip title={"Du kannst dich selbst einladen, um das Kundenerlebnis zu testen"}>
                <FormControlLabel
                  control={<Switch value={selfInvite} onChange={selfInviteChange} />}
                  label={
                    <>
                      Mich selbst einladen <InfoIcon fontSize='inherit' sx={{ verticalAlign: "middle" }} />
                    </>
                  }
                />
              </Tooltip> */}
                <FormControl fullWidth>
                  <TextField
                    data-cy='invite-lead-name-input'
                    variant='outlined'
                    id='name'
                    value={name}
                    label='Name'
                    onChange={handleNameChange}
                    error={nameError}
                    helperText={nameError ? "Pflichtfeld!" : ""}
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    data-cy='invite-lead-email-input'
                    variant='outlined'
                    id='email'
                    type='email'
                    value={email}
                    label='E-mail'
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={!emailError ? "" : email ? "Bitte geben Sie eine gültige E-mail an" : "Pflichtfeld!"}
                    required
                  />
                </FormControl>
                <FormControlLabel control={<Switch value={cc} onChange={handleCcChange} />} label='Kopie an meine E-Mail schicken' />
                <Stack direction='row' spacing={2} alignItems={"center"}>
                  <NovoCustomSelect
                    data-cy='invite-lead-product-selector'
                    label='Produkt'
                    options={productOptions}
                    value={product}
                    onUpdate={handleProductChange}
                    error={productError}
                    helperText={productError ? "Pflichtfeld!" : ""}
                    required
                  />
                  {Product[productMapping.get(product)!] === Product.consumptionCertificate && (
                    <NovoTooltip title={<CCInviteClarification leadName={name} leadEmail={email} />}>
                      <HelpIcon />
                    </NovoTooltip>
                  )}
                </Stack>
                {product && <Voucher product={Product[productMapping.get(product)!]} voucherSelected={handleFreeChange} />}
                <Fade in={showSuccess} timeout={500} unmountOnExit>
                  <Alert data-cy='success-invite-dialog' sx={{ marginTop: 2 }} severity='success'>
                    Das hat geklappt!
                  </Alert>
                </Fade>
                <Fade in={!!errorMessage} timeout={500} unmountOnExit>
                  <Alert sx={{ marginTop: 2 }} severity='error'>
                    {errorMessage}
                  </Alert>
                </Fade>
                <DialogActions sx={{ p: 3 }}>
                  <ButtonWithSpinner data-cy='invite-lead-confirm-btn' variant={"contained"} loading={isLoading} label='Einladen' onClick={handleSubmit} />
                </DialogActions>
              </Stack>
            </Grid>
            <Grid size={6}>
              {template?.emailMessage && <div dangerouslySetInnerHTML={{ __html: template?.emailMessage.replace("{name}", name) }} />}
              {!template?.emailMessage && <Skeleton height={700} animation='wave' variant='rectangular' />}
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

import Stack from "@mui/material/Stack";
import { RowProps } from "../LeadList";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { CCState, Product } from "../../../../../types/cockpit/types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import ProgressDial from "./ProgressDial";
import { Fragment, useEffect, useState } from "react";
import { useGetConsumptionCertPdfLink } from "../../../../../hooks/useGetConsumptionCertPdfLink";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DisplayConsumptionCertificateDialog } from "../DisplayConsumptionCertificateDialog";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export default function CCWorkflow({ row, openInviteLeadModal }: { row: RowProps; openInviteLeadModal: (product: Product) => void }) {
  const renderStates = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-end"}>
        <Stack>
          <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Aktueller Status</Typography>
          <Chip label={CCState.toString(row.cc.state)} />
        </Stack>
        {row.cc.state !== CCState.FINAL && <NavigateNextIcon sx={{ alignSelf: "flex-end", mb: "0.25rem" }} />}
        {row.cc.state !== CCState.FINAL && (
          <Stack>
            <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Nächster Schritt</Typography>
            <Chip label={CCState.toString(row.cc.state + 1)} />
          </Stack>
        )}
      </Stack>
    );
  };
  if (row.cc.state === CCState.START) {
    return (
      <Stack direction={"row"} spacing={5} alignItems={"flex-end"}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "12rem",
            alignSelf: "center",
          }}
        >
          Energieausweis
        </Typography>
        <Tooltip
          title={
            "Ein Energieausweis ist gesetzlich vorgeschrieben für Immobilien, die verkauft oder vermietet werden sollen sowie für Neubauten. Zur Erfüllung von ESG-Anforderungen klassifizieren Banken ihren Bestand ebenfalls mittels Energieausweisen."
          }
        >
          <Button
            variant='contained'
            sx={{
              fontSize: "0.7rem",
            }}
            onClick={() => openInviteLeadModal(Product.consumptionCertificate)}
          >
            {CCState.toString(row.cc.state)}
          </Button>
        </Tooltip>
      </Stack>
    );
  }
  return (
    <Stack direction={"row"} spacing={5} alignItems={"flex-end"}>
      <Typography
        sx={{
          fontSize: "0.9rem",
          fontWeight: "fontWeightBold",
          width: "12rem",
          alignSelf: "center",
        }}
      >
        Energieausweis
      </Typography>
      <ProgressDial progress={(100 * row.cc.state) / (CCState.all().length - 1)} />
      {renderStates()}
      <CcAction row={row} />
    </Stack>
  );
}

function CcAction({ row }: { row: RowProps }) {
  const [isCcAnswersOpen, setCcAnswersOpen] = useState(false);
  const { getConsumptionCertLink, getDocumentData } = useGetConsumptionCertPdfLink<{ url: string } | undefined>();

  useEffect(() => {
    if (row.cc.state === CCState.FINAL && !row.cc.bubbleCdnUrl) {
      getConsumptionCertLink(row.cc.id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCcAnswersModal = () => {
    setCcAnswersOpen(!isCcAnswersOpen);
  };

  if (row.cc.state === CCState.SUBMITTED) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={toggleCcAnswersModal}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        <DisplayConsumptionCertificateDialog consumptionCertificate={row.cc.ccData} open={isCcAnswersOpen} handleClose={toggleCcAnswersModal} />
      </Box>
    );
  } else if (row.cc.state === CCState.FINAL) {
    return (
      <Link
        href={getDocumentData ? getDocumentData.url : row.cc.bubbleCdnUrl}
        underline={"always"}
        sx={{
          color: "text.primary",
          fontSize: "0.9rem",
          textDecorationColor: "inherit",
        }}
      >
        <PictureAsPdfIcon
          sx={{
            fontSize: "1.2rem",
            verticalAlign: "middle",
          }}
        />
        Energieausweis einsehen
      </Link>
    );
  } else {
    return <></>;
  }
}

import { Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import FormScreen from "../FormScreen";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NovoImageUpload from "../../../../components/common/NovoImageUpload";
import { useState } from "react";
import { HousePhotos } from "../../../../types/RenovationQuestionnaire";
import useUpsertRenovations from "../../../../hooks/useUpsertRenovations";

export default function PhotoUploadScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [uploading, setUploading] = useState<boolean>(false);
  const [photosError, setPhotosError] = useState<string>();
  const [housePhotos, setHousePhotos] = useState<HousePhotos>(renovations.answers?.housePhotos || {});
  const { uploadFile } = useUpsertRenovations();
  const validate = () => {
    if (!housePhotos.photos || housePhotos.photos.length === 0) {
      setPhotosError("Pflichtfeld");
      return false;
    } else {
      setPhotosError(undefined);
    }
    return true;
  };
  const upload = async ({ photos }: HousePhotos) => {
    setUploading(true);
    if (photos) {
      for (const photo of photos) {
        if (!photo.file) continue;
        await uploadFile(photo);
      }
    }
    setUploading(false);
  };
  const validateAndSave = () => {
    if (validate()) {
      upload(housePhotos).then(() => {
        const newState = { ...renovations, answers: { ...renovations.answers, housePhotos } };
        setRenovations(newState);
        next(newState);
      });
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 10: </strong>Upload von Gebäudefotos (max. 4 Bilder)
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' spacing={1} sx={{ alignItems: "top" }}>
            <NovoImageUpload
              multiple
              label='Fotos Hochladen'
              direction={isMobile ? "row" : "column"}
              maxImages={4}
              required
              value={housePhotos.photos}
              onChange={(photos) => {
                setHousePhotos({ ...housePhotos, photos });
                setPhotosError(undefined);
              }}
              error={!!photosError}
              helperText={photosError}
            />
            <Tooltip title='Laden Sie hier bitte 4 Foto der Außenfassaden Ihres Gebäudes hoch, ein Fotos von jeder Seite.'>
              <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={uploading || isUpdateRunning}
      progressLabel='Gebäudefotos'
      progressValue={83}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Die Gebäudefotos werden benötigt,
      <br />
      um einen ersten Eindruck Ihrer
      <br />
      Immobilien zu erlangen.
      <br /> <br />
      Sie ersetzen nicht den
      <br />
      Vor Ort-Besuch, helfen uns
      <br />
      jedoch, eine Ersteinschätzung
      <br />
      Ihres Gebäudes vorzunehmen.
    </Typography>
  );
}

import { Typography, Box, TextField, Stack, Alert } from "@mui/material";
import ActionContainer from "../../components/ActionContainer";
import { PasswordValidityTooltips } from "./PasswordValidityTooltips";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import PasswordsMatchTooltip from "./PasswordsMatchTooltip";
import { PasswordValidationResult, passwordValidator } from "./passwordValidator";
import { useResetPasswordWithCode } from "../../hooks/useResetPasswordWithCode";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { authErrorTypeToErrorText } from "../../hooks";
import { ShowPasswordToggle } from "./ShowPasswordToggle";

type ForgotPasswordChangeState = {
  code: string;
  codeError: boolean;
  password: string;
  passwordError: boolean;
  passwordRetype: string;
  passwordRetypeError: boolean;
};

export default function ForgotPasswordChange() {
  const { isLoading, error, resetPassword, resetSuccessful } = useResetPasswordWithCode();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const username = params.get("username")?.toLocaleLowerCase() ?? "";
  if (!username) {
    // if username is not set, just navigate away. Should never happen
    // if user uses the site normally ;)
    navigate("/signin");
  }
  const [formState, setFormState] = useState<ForgotPasswordChangeState>({
    code: "",
    codeError: false,
    password: "",
    passwordError: false,
    passwordRetype: "",
    passwordRetypeError: false,
  });
  const [passwordValidity, setPasswordValidity] = useState<PasswordValidationResult>({
    hasLowerCase: false,
    hasMinLength: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperCase: false,
    passwordsMatch: true,
    passwordHasValue: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);

  useEffect(() => {
    setPasswordValidity(passwordValidator(formState.password ?? "", formState.passwordRetype ?? ""));
  }, [formState.password, formState.passwordRetype]);

  function codeChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setFormState({ ...formState, code: event.target.value, codeError: false });
  }
  function newPasswordChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setFormState({ ...formState, password: event.target.value, passwordError: false });
  }
  function newPasswordRetypeChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setFormState({ ...formState, passwordRetype: event.target.value, passwordRetypeError: false });
  }

  function onSubmitClick(): void {
    if (!formState.code) {
      setFormState({ ...formState, codeError: true });
      return;
    }
    if (
      !formState.password ||
      !passwordValidity.hasLowerCase ||
      !passwordValidity.hasUpperCase ||
      !passwordValidity.hasMinLength ||
      !passwordValidity.hasNumber ||
      !passwordValidity.hasSpecialChar
    ) {
      setFormState({ ...formState, passwordError: true });
      return;
    }
    if (!formState.passwordRetype || !passwordValidity.passwordsMatch) {
      setFormState({ ...formState, passwordRetypeError: true });
      return;
    }

    resetPassword(username, formState.code, formState.password);
  }

  const navigateToLogin = () => {
    if (resetSuccessful) {
      navigate(`/signin?username=${encodeURIComponent(resetSuccessful.username)}`);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowPasswordRetype = () => {
    setShowPasswordRetype(!showPasswordRetype);
  };

  if (resetSuccessful) {
    return (
      <ActionContainer minWidth={650} minHeight={300} sticker={{ image: "thumbs-up.svg", t: -20, r: -14, s: 70 }}>
        <Typography variant='h5' data-cy='box-title' fontWeight={600}>
          Dein Password wurde aktualisiert
        </Typography>
        <Box paddingTop={16} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='continue-to-login-btn'
            loading={isLoading}
            onClick={navigateToLogin}
            label='Weiter zum Login'
            size='large'
            variant={"contained"}
            disabled={isLoading}
          />
        </Box>
      </ActionContainer>
    );
  }

  return (
    <ActionContainer sticker={{ image: "hot-air-balloon.svg", t: -25, r: -5, s: 80 }}>
      <Typography variant='h4' fontWeight={600}>
        Aktualisiere dein Passwort
      </Typography>
      <Typography paddingTop={4}>Bitte gib dein neues Passwort ein.</Typography>
      <Stack paddingTop={4}>
        <Box display={"flex"}>
          <TextField
            fullWidth
            label='Zurücksetzungscode'
            variant='outlined'
            data-cy='reset-code-input'
            id='reset-code-input'
            value={formState.code}
            type='text'
            helperText={"Bitte gib den Code ein, der an deine E-Mail geschickt wurde. Schau’ bitte auch im Spam / Junk Mail-Ordner nach."}
            error={!!formState.codeError}
            onChange={codeChangeHandler}
            required={true}
          />
        </Box>
        <ShowPasswordToggle sx={{ paddingTop: 2, paddingBottom: 1 }} isShowing={showPassword} onClick={toggleShowPassword} />
        <Box display={"flex"}>
          <TextField
            fullWidth
            label='Passwort'
            variant='outlined'
            data-cy='password-input'
            id='password-input'
            value={formState.password}
            type={showPassword ? "text" : "password"}
            helperText={formState.passwordError ? "Bitte prüfe dein neues Passwort" : undefined}
            error={!!formState.passwordError}
            onChange={newPasswordChangeHandler}
            required={true}
          />
        </Box>
        <PasswordValidityTooltips validation={passwordValidity} />
        <ShowPasswordToggle sx={{ paddingTop: 2, paddingBottom: 1 }} isShowing={showPasswordRetype} onClick={toggleShowPasswordRetype} />
        <Box display={"flex"}>
          <TextField
            fullWidth
            label='Passwort wiederholen'
            variant='outlined'
            data-cy='password-input-retype'
            id='password-input-retype'
            value={formState.passwordRetype}
            type={showPasswordRetype ? "text" : "password"}
            onChange={newPasswordRetypeChangeHandler}
            error={formState.passwordRetypeError}
            helperText={formState.passwordRetypeError ? "Bitte prüfe dein neues Passwort" : undefined}
            required={true}
          />
        </Box>
        <PasswordsMatchTooltip {...passwordValidity} />
        <Box paddingTop={4} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='reset-pswd-send-btn'
            loading={isLoading}
            onClick={onSubmitClick}
            label='Aktualisieren'
            size='large'
            variant={"contained"}
            disabled={isLoading}
          />
        </Box>
        {error && (
          <Alert data-cy='change-error-alert' sx={{ marginTop: 2 }} severity='error'>
            {authErrorTypeToErrorText(error.type)}
          </Alert>
        )}
      </Stack>
    </ActionContainer>
  );
}

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import useFetchIsfpProcesses from "../../../hooks/useFetchIsfpProcesses";
import { IsfpProcess, IsfpProcessStatus } from "../../../types/cockpit/types";
import { IsfpProcessTable } from "./IsfpProcessTable";

export function IsfpProcessList() {
  const { isfpProcesses, fetchIsfpProcesses, isLoading, error } = useFetchIsfpProcesses();

  const [searchQuery, setSearchQuery] = useState<string>();
  const [filteredIsfpProcesses, setFilteredIsfpProcesses] = useState<Array<IsfpProcess>>();

  useEffect(() => {
    fetchIsfpProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.SyntheticEvent, value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const searchFilter = (isfpProcess: IsfpProcess) => {
      if (!searchQuery) {
        return true;
      }
      const normalisedQuery = searchQuery.toLowerCase();
      return (
        isfpProcess.leadName?.toLowerCase().includes(normalisedQuery) ||
        isfpProcess.leadEmail?.toLowerCase().includes(normalisedQuery) ||
        (isfpProcess.status ? IsfpProcessStatus.toString(isfpProcess.status)?.toLowerCase().includes(normalisedQuery) : false)
      );
    };
    setFilteredIsfpProcesses(isfpProcesses?.filter(searchFilter));
  }, [isfpProcesses, searchQuery]);

  const handleRefresh = () => {
    fetchIsfpProcesses();
  };

  const onChange = (changed: IsfpProcess) => {
    if (!filteredIsfpProcesses) {
      return;
    }
    const copiedProcesses = [...filteredIsfpProcesses];
    const changedIdx = copiedProcesses.findIndex((process) => process.leadEmail == changed.leadEmail && process.referrerEmail == changed.referrerEmail);
    if (changedIdx >= 0) {
      copiedProcesses[changedIdx] = changed;
      setFilteredIsfpProcesses(copiedProcesses);
    }
  };

  return (
    <Box sx={{ flexGrow: 7, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <Typography variant='h5' style={{ fontWeight: 600 }}>
          iSFP Prozesse
        </Typography>
        <TableTopActions onSearchChange={handleSearch} onRefresh={handleRefresh} />
        <IsfpProcessTable isLoading={isLoading} error={error} isfpWorkflows={filteredIsfpProcesses} onChange={onChange} />
      </Stack>
    </Box>
  );
}

type TableTopActionsProps = {
  onSearchChange: (event: React.SyntheticEvent, value: string) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, onRefresh }: TableTopActionsProps) {
  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label='Suchen'
              data-cy={"search-isfp-process-input"}
              placeholder='Name, E-mail, Status...'
              slotProps={{
                input: {
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          options={[]}
          onChange={onSearchChange}
          sx={{ flexGrow: 1, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-isfp-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}

import { CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import { AuthError, AuthErrorType } from "./useAuth";
import { cognitoClientId, cognitoPoolId } from "../utils/params";

import { useState } from "react";

export type AccountParameters = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  password: string;
};

const userPool = new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() });

export default function useCreateAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [createSuccess, setCreateSuccess] = useState<{ username: string } | undefined>(undefined);

  const createAccount = async (account: AccountParameters) => {
    setIsLoading(true);
    setCreateSuccess(undefined);
    setError(undefined);
    const userAttributes: CognitoUserAttribute[] = [
      new CognitoUserAttribute({ Name: "given_name", Value: account.firstName }),
      new CognitoUserAttribute({ Name: "family_name", Value: account.lastName }),
      new CognitoUserAttribute({ Name: "phone_number", Value: account.telephone }),
      new CognitoUserAttribute({ Name: "email", Value: account.email }),
    ];
    const validationData: CognitoUserAttribute[] = [new CognitoUserAttribute({ Name: "email", Value: account.email })];
    await new Promise((resolve, reject) => {
      userPool.signUp(account.email, account.password, userAttributes, validationData, (err, success) => {
        if (err) {
          switch (err.name) {
            case "UsernameExistsException":
              setError({ type: AuthErrorType.ACCOUNT_CREATION_USERNAME_ALREADY_EXISTS });
              break;
            case "InvalidParameterException":
              setError({ type: AuthErrorType.ACCOUNT_CREATION_INVALID_PARAMETER });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_CREATING_NEW_USER });
          }
          return reject(err);
        }
        setCreateSuccess({ username: account.email });
        setError(undefined);
        return resolve(success);
      });
    })
      .catch((err) => {
        console.log(JSON.stringify(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { error, isLoading, createSuccess, createAccount };
}

import { CssBaseline } from "@mui/material";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { AuthContext } from "./Contexts";
import { ProtectedRoute } from "./components/ProtectedRoute";
import DevTools from "./components/dev-tools/DevTools";
import useAuth from "./hooks/useAuth";
import ConfirmAccount from "./pages/auth/ConfirmAccount";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPasswordChange from "./pages/auth/ForgotPasswordChange";
import PasswordChangeNeeded from "./pages/auth/PasswordChangeNeeded";
import Signin from "./pages/auth/Signin";
import Signup from "./pages/auth/Signup";
import Cockpit from "./pages/cockpit/Cockpit";
import { Consultants } from "./pages/cockpit/consultants/Consultants";
import ConsumptionCertificate from "./pages/cockpit/consumption-certificate/ConsumptionCertificate";
import ConsumptionCertificateModernisation from "./pages/cockpit/consumption-certificate/ConsumptionCertificateModernisation";
import { Contents } from "./pages/cockpit/contents/Contents";
import Invites from "./pages/cockpit/invites/Invites";
import { IsfpsAdminPanel } from "./pages/cockpit/isfp/IsfpsAdminPanel";
import Leads from "./pages/cockpit/leads/Leads";
import Metrics from "./pages/cockpit/metrics/Metrics";
import AddProperty from "./pages/cockpit/one-pager/AddProperty";
import OnePager from "./pages/cockpit/one-pager/OnePager";
import OnePagerKfw from "./pages/cockpit/one-pager/OnePagerKfw";
import OnePagerSanierungspflicht from "./pages/cockpit/one-pager/OnePagerSanierungspflicht";
import Property from "./pages/cockpit/one-pager/Property";
import Embedded from "./pages/embedded/Embedded";
import FoerderWelt from "./pages/embedded/FoerderWelt";
import FoerderWeltEmbedded from "./pages/embedded/FoerderWeltEmbedded";
import ConsumptionCertificateRequestPage from "./pages/funnels/consumption-certificate/ConsumptionCertificateRequestPage";
import { MailingPreferences } from "./pages/funnels/email/MailingPreferences";
import RenovationsFunnel from "./pages/funnels/isfp/renovations/RenovationsFunnel";
import StatusQuoFunnel from "./pages/funnels/isfp/statusquo/StatusQuoFunnel";
import StripeOrderPaymentPage from "./pages/funnels/stripe/StripeOrderPaymentPage";
import StripeOrderPaymentResultPage from "./pages/funnels/stripe/StripeOrderPaymentResultPage";
import bsh from "./themes/bsh";
import coba from "./themes/coba";
import novo from "./themes/novo";
import novoDark from "./themes/novo-dark";
import psd from "./themes/psd";
import vb from "./themes/vb";
import { Themes } from "./types/types";
import { env } from "./utils/params";
import { retrieveRum } from "./AwsRum";
import { ThankYouAfterContact } from "./pages/funnels/consumption-certificate/ThankYouAfterContact";

declare module "@mui/material/styles" {
  interface Theme {
    name: Themes;
    logo: string;
    logoClass: string;
  }

  interface ThemeOptions {
    name: Themes;
    logo?: string;
    logoClass?: string;
  }
}

const App = () => {
  const [searchParams] = useSearchParams();
  const tenantParam = searchParams.get("tenant");
  const session = useAuth();
  const [theme, setTheme] = useState<Theme>(novo);
  const themes = ["novo", "novoDark", "vb", "psd", "bsh", "coba"];
  const [themeName, setThemeName] = useState<string>(themes[0]);
  const [overrideThemeName, setOverrideThemeName] = useState<string>();

  const handleOverrideThemeName = (themeName: string) => {
    setOverrideThemeName(themeName);
    selectTheme(themeName);
  };

  const selectTheme = (themeName: string) => {
    setThemeName(themeName);
    switch (themeName) {
      case "novo":
        setTheme(novo);
        break;
      case "novoDark":
        setTheme(novoDark);
        break;
      case "psd":
        setTheme(psd);
        break;
      case "vb":
        setTheme(vb);
        break;
      case "bsh":
        setTheme(bsh);
        break;
      case "coba":
        setTheme(coba);
        break;
      default:
        setTheme(novo);
        break;
    }
  };

  useEffect(() => {
    if (!overrideThemeName) {
      const tenantName = session.user?.tenant?.name.toLowerCase();

      if (tenantName?.includes("psd")) {
        selectTheme("psd");
      } else if (tenantName?.includes("volksbank") || tenantName?.includes("vr_bank")) {
        selectTheme("vb");
      } else if (tenantName?.includes("bsh")) {
        selectTheme("bsh");
      } else if (tenantName?.includes("coba")) {
        selectTheme("coba");
      }
    }
  }, [session, overrideThemeName]);

  useEffect(() => {
    if (tenantParam) {
      setOverrideThemeName(tenantParam);
      selectTheme(tenantParam);
    }
  }, [tenantParam]);

  useEffect(() => {
    retrieveRum();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContext.Provider value={session}>
        <Routes>
          <Route path='/' element={<Signin />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/confirm-account' element={<ConfirmAccount />} />
          <Route path='/password-change-needed' element={<PasswordChangeNeeded />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/forgot-password-change' element={<ForgotPasswordChange />} />
          <Route path='/consumption-certificate' element={<ConsumptionCertificateRequestPage />} />
          <Route path='/consumption-certificate/contact-thanks' element={<ThankYouAfterContact />} />

          <Route path='/orders/:orderId/payment/stripe' element={<StripeOrderPaymentPage />} />
          <Route path='/orders/:orderId/payment/stripe/return' element={<StripeOrderPaymentResultPage />} />
          <Route path='/foerder-welt' element={<FoerderWelt />} />
          <Route path='/embedded' element={<Embedded />} />
          <Route path='/foerder-welt-embedded' element={<FoerderWeltEmbedded />} />
          <Route path='/schnellcheck' element={<StatusQuoFunnel />} />
          <Route path='/s/:referrerCode' element={<StatusQuoFunnel renderIntroduction={true} />} />
          {env() !== "prod" && <Route path='/sanierungscheck' element={<RenovationsFunnel />} />}
          <Route path='/mailing-settings' element={<MailingPreferences />} />
          <Route
            path='/cockpit'
            element={
              <ProtectedRoute>
                <Cockpit />
              </ProtectedRoute>
            }
          >
            <Route path='leads' element={<Leads />} />
            <Route path='invites' element={<Invites />} />

            <Route path='metrics' element={<Metrics />} />

            <Route path='consultants' element={<Consultants />} />
            {env() !== "prod" && <Route path='contents' element={<Contents />} />}

            <Route path='isfps' element={<IsfpsAdminPanel />} />

            <Route path='consumption-certificates' element={<ConsumptionCertificate />} />
            <Route path='consumption-certificates/:certificateId/modernisation-recommendations' element={<ConsumptionCertificateModernisation />} />

            <Route path='one-pager' element={<OnePager />} />
            <Route path='one-pager/:id' element={<Property />} />
            <Route path='one-pager/property' element={<AddProperty />} />
            <Route path='one-pager/:id/kfw' element={<OnePagerKfw />} />
            <Route path='one-pager/:id/sanierungspflicht' element={<OnePagerSanierungspflicht />} />
          </Route>
        </Routes>
      </AuthContext.Provider>
      <DevTools themes={themes} currentTheme={themeName} setTheme={handleOverrideThemeName} />
    </ThemeProvider>
  );
};

export default App;

import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactElement, useEffect, useState } from "react";

interface NovoSelectProps extends FormControlProps {
  label: string;
  dataCy?: {
    field: string;
    values: string[];
  };
  name?: string;
  required?: boolean;
  options: Array<string>;
  value: string;
  error?: boolean;
  helperText?: string;
  onUpdate?: (value: string) => void;
}

export default function NovoSelect({
  label,
  dataCy,
  options,
  value,
  name = label.toLowerCase().replace(" ", "-"),
  required = false,
  error = false,
  helperText,
  onUpdate,
  ...props
}: NovoSelectProps) {
  const [_value, setValue] = useState("");

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handler = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onUpdate) {
      onUpdate(newValue);
    }
  };

  const _options: Array<ReactElement> = [];
  for (let i = 0; i < options.length; i++) {
    _options.push(
      <MenuItem key={i} value={options[i]} data-cy={`${dataCy?.values[i]}-${dataCy?.field}-option`}>
        {options[i]}
      </MenuItem>,
    );
  }

  return (
    <FormControl error={error} fullWidth {...props}>
      <InputLabel id={`${name}-selector-label`} required={required}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-selector-label`}
        id={`${name}-selector`}
        data-cy={dataCy?.field}
        value={_value}
        label={label}
        onChange={handler}
        required={required}
      >
        {_options}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

import { Fragment, useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { TableSortLabel } from "@mui/material";
import { InviteProps } from "./InviteList";
import { Product } from "../../../types/cockpit/types";

type Order = "asc" | "desc";
type OrderBy = "name" | "email" | "createdAt" | "product";

export interface InviteTableProps {
  isLoading: boolean;
  error?: string | string[];
  invites?: InviteProps[];
}

export default function InviteTable({ isLoading, error, invites }: InviteTableProps) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>("createdAt");
  const [sortedRows, setSortedRows] = useState<InviteProps[]>([]);

  useEffect(() => {
    if (!invites) return;
    const sorted = [...invites.sort(inviteSorter(orderBy, order))];
    setSortedRows(sorted);
  }, [invites, order, orderBy]);

  if (invites && invites?.length < pageSize * page) {
    setPage(0);
    return null;
  }
  const inviteSorter = (property: keyof InviteProps, order: Order) => {
    return (a: InviteProps, b: InviteProps) => {
      const inverter = order === "asc" ? 1 : -1;
      const left = a[property];
      const right = b[property];
      if (!left || !right) return 0;
      if (left < right) {
        return -1 * inverter;
      } else if (left > right) {
        return 1 * inverter;
      } else {
        return 0;
      }
    };
  };

  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(newOrder);
    }
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = Number.parseInt(event.target.value);
    setPageSize(newPageSize);
    setPage(0);
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement>, page: number) => {
    setPage(page);
  };

  if (isLoading || !invites) {
    return <LoadingData />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }
  if (invites?.length === 0) {
    return <EmptyTable />;
  }
  return (
    <Table aria-label='collapsible table' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={orderBy === "name"}
              direction={order}
              onClick={() => {
                handleOrderChange("name");
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "email"}
              direction={order}
              onClick={() => {
                handleOrderChange("email");
              }}
            >
              Email
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "createdAt"}
              direction={order}
              onClick={() => {
                handleOrderChange("createdAt");
              }}
            >
              Einladungsdatum
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "product"}
              direction={order}
              onClick={() => {
                handleOrderChange("product");
              }}
            >
              Produkt
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{sortedRows?.slice(page * pageSize, (page + 1) * pageSize).map((row) => <Row key={row.id} row={row} />)}</TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={handlePageSizeChange}
            component='td'
            count={invites?.length || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handlePageChange}
            colSpan={5}
            labelRowsPerPage='Einträge pro Seite'
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

function LoadingData() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      </Stack>
    </Box>
  );
}

function LoadingError({ error }: { error: string | string[] }) {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>{error}</Typography>
    </Box>
  );
}

function EmptyTable() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>Keine Ergebnisse gefunden</Typography>
    </Box>
  );
}

function Row({ row }: { row: InviteProps }) {
  let product = "";
  switch (row.product) {
    case Product.consumptionCertificate:
      product = "Energieausweis";
      break;
    case Product.isfp:
      product = "Schnellcheck / iSFP";
      break;
    case Product.onePager:
      product = "Sanierungsgutachten";
      break;
    default:
      product = "";
      break;
  }
  return (
    <Fragment>
      <TableRow
        sx={{
          height: 75,
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
      >
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.createdAt.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}</TableCell>
        <TableCell>
          <Chip sx={{ mr: 1 }} label={product} />
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}

import { useState } from "react";
import { AuthError, AuthErrorType, userPool } from "./useAuth";
import { CognitoUser } from "amazon-cognito-identity-js";

export const useForgotPasswordCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [sendCodeSuccess, setSendCodeSuccess] = useState<{ username: string } | undefined>(undefined);

  const sendForgotPasswordCode = async (username: string) => {
    setIsLoading(true);
    setSendCodeSuccess(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: username });
    await new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (success) => {
          setSendCodeSuccess({ username });
          resolve(success);
        },
        onFailure: (err) => {
          switch (err.name) {
            case "UserNotFoundException":
              setError({ type: AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE_USER_INCORRECT });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE });
          }
          return reject(err);
        },
      });
    })
      .catch((err) => {
        console.log(JSON.stringify(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return { isLoading, sendCodeSuccess, error, sendForgotPasswordCode };
};

import { FunctionComponent } from "react";
import { Button, List, Stack, Typography } from "@mui/material";
import { ContinueCardListItem } from "./ContinueCardListItem";

type ContinueCardProps = {
  setNextQuestion: (questionNbr: number) => void;
};

export const ContinueCard: FunctionComponent<ContinueCardProps> = ({ setNextQuestion }) => {
  return (
    <>
      <Stack
        spacing={3}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          justifyContent: "space-between",
          height: "100% ",
          p: {
            xs: 3,
            md: 6,
          },
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: "24px", color: "#000000", hyphens: "auto", overflowWrap: "break-word" }}>
          Jetzt erfahren, welche Sanierungsmaßnahmen sich für Sie lohnen:
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "16px", color: "#000000", py: 0 }}>Mit einem individuellen Sanierungsfahrplan (iSFP)</Typography>
        <List>
          <ContinueCardListItem text='Überblick über Maßnahmen, Kosten und Förderungen' />
          <ContinueCardListItem text='dena-zertifiziert' />
          <ContinueCardListItem text='bis zu 850€ Zuschuss' />
          <ContinueCardListItem text='doppelte förderfähige Summe' />
          <ContinueCardListItem text='5% Extra-Förderung' />
        </List>
        <Button
          variant='contained'
          sx={{ fontSize: "20px", borderRadius: 4, lineHeight: 1.25, marginTop: "40px" }}
          onClick={() => {
            setNextQuestion(6);
          }}
          data-cy='report-next-btn'
        >
          Kostenloses Angebot anfordern
        </Button>
      </Stack>
    </>
  );
};

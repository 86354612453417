import axios from "axios";
import { useParams } from "react-router";
import { apiEndpoint } from "../utils/params";
import { useState, useEffect, useContext } from "react";
import { Property } from "../types/types";
import { AuthContext } from "../Contexts";
import { retrieveRum } from "../AwsRum";

const API_ENDPOINT = `${apiEndpoint()}/properties/`;

export default function useFetchSingleProperty() {
  const { id } = useParams();
  const [property, setProperty] = useState<Property>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token, logout } = useContext(AuthContext);

  useEffect(() => {
    const fetchSingleProperty = async () => {
      // TODO need to check if token is in localstorage
      try {
        const { data } = await axios.get<Property>(API_ENDPOINT + id, {
          headers: {
            Authorization: `Bearer ${token?.id_token}`,
          },
        });
        setProperty(data);
        setIsLoading(false);
      } catch (e) {
        if (e.response.status === 401) {
          logout();
        } else {
          retrieveRum()?.recordError(e);
        }
      }
    };
    fetchSingleProperty();
  }, [id, logout, token]);

  return { property, setProperty, isLoading };
}

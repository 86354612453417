import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Status } from "../../../components/one-pager/Status";
import { EnergyClassTable } from "../../../components/one-pager/EnergyClassTable";
import { InvestmentTable } from "../../../components/one-pager/InvestmentTable";
import { PropertyInfoTable } from "../../../components/one-pager/PropertyInfoTable";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useFetchSingleProperty from "../../../hooks/useFetchSingleProperty";
import { SanierungspflichtRenovationTable } from "../../../components/one-pager/SanierungspflichtRenovationTable";
import { calculateEnergyCostSavings } from "../../../utils/utils";

export default function OnePagerSanierungspflicht() {
  const { property, setProperty, isLoading } = useFetchSingleProperty();

  return (
    <>
      <Grid container justifyContent='center' padding={5}>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Stack spacing={4} alignItems='center'>
            <Typography variant='h4' align='center' style={{ fontWeight: 600 }}>
              {property!.property.address}
            </Typography>
            <Stack direction='row' spacing={8} justifyContent='center' alignItems='center'>
              <Status title={"Before"} {...property!.analysis.status} />
              <Status title={"After"} {...property!.analysis.sanierungspflicht.status} />
            </Stack>
            <Stack direction='row' spacing={4} justifyContent='center' alignItems='center'>
              <Stack spacing={4} alignItems='center'>
                <EnergyClassTable before={property!.analysis.consumption} after={property!.analysis.sanierungspflicht.consumption} />
                <PropertyInfoTable {...property!.property} />
              </Stack>
              <SanierungspflichtRenovationTable {...{ property, setProperty }} />
            </Stack>
            <InvestmentTable
              {...{
                ...property!.analysis.sanierungspflicht.costs,
                savings: calculateEnergyCostSavings(property!.analysis.consumption, property!.analysis.sanierungspflicht.consumption),
              }}
            />
          </Stack>
        )}
      </Grid>
    </>
  );
}

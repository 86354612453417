import { Alert, Box, Checkbox, FormControl, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { authErrorTypeToErrorText } from "../../hooks";
import useCreateAccount from "../../hooks/useCreateAccount";
import AuthContainer from "./AuthContainer";
import { PasswordValidityTooltips } from "./PasswordValidityTooltips";
import { GermanPhoneInput } from "./GermanPhoneInput";
import { ShowPasswordToggle } from "./ShowPasswordToggle";
import { isValidEmail } from "./emailValidator";
import { PasswordValidationResult, passwordValidator } from "./passwordValidator";
import { phoneNumberIsValid } from "./phoneNumberValidator";

export default function Signup() {
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email") ?? "";
  const { createSuccess, error, isLoading, createAccount } = useCreateAccount();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState(emailParam);
  const [emailError, setEmailError] = useState(false);
  const [telephone, setTelephone] = useState("+49");
  const [telephoneError, setTelephoneError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState<PasswordValidationResult>({
    hasLowerCase: false,
    hasMinLength: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperCase: false,
    passwordsMatch: true,
    passwordHasValue: false,
  });

  useEffect(() => {
    const pswdValRslt = passwordValidator(password);
    setPasswordValidity(pswdValRslt);
  }, [password]);

  useEffect(() => {
    if (createSuccess) {
      navigate(`/confirm-account?username=${encodeURIComponent(createSuccess.username)}`);
    }
  }, [createSuccess, navigate]);

  const firstNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameError(false);
  };

  const lastNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameError(false);
  };

  const emailHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.toLowerCase());
    setEmailError(false);
  };

  const telephoneHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setTelephone(event.target.value);
    setTelephoneError(false);
  };

  const passwordHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const validateFields = (): boolean => {
    let isValid = true;
    if (firstName.length < 3) {
      setFirstNameError(true);
      isValid = false;
    }
    if (lastName.length < 3) {
      setLastNameError(true);
      isValid = false;
    }
    if (!isValidEmail(email)) {
      setEmailError(true);
      isValid = false;
    }
    if (!phoneNumberIsValid(telephone)) {
      setTelephoneError(true);
      isValid = false;
    }
    if (
      !passwordValidity.hasLowerCase ||
      !passwordValidity.hasMinLength ||
      !passwordValidity.hasNumber ||
      !passwordValidity.hasUpperCase ||
      !passwordValidity.hasSpecialChar
    ) {
      setPasswordError(true);
      isValid = false;
    }
    return isValid;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const createCustomerAccount = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const form = event.currentTarget?.form;
    const isValid = validateFields();
    if (isValid && form && form.checkValidity()) {
      await createAccount({
        firstName,
        lastName,
        email,
        telephone,
        password,
      });
    } else if (form && form.reportValidity()) {
      const errorElement = findElementOnError(form);
      errorElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const findElementOnError = (form: HTMLFormElement): Element | undefined => {
    for (let i = 0; i < form.elements.length; i++) {
      if (form.elements[i].ariaInvalid === "true") {
        return form.elements[i];
      }
    }
    return;
  };

  return (
    <AuthContainer>
      <Stack spacing={2} component={"form"}>
        <Typography variant='h4' fontWeight={600}>
          Account erstellen
        </Typography>
        <Typography>
          Du hast bereits einen Account?{" "}
          <Link to='/signin' style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }}>
            Zum Login
          </Link>
        </Typography>
        <FormControl fullWidth>
          <TextField
            data-cy='first-name'
            variant='outlined'
            id='first-name'
            value={firstName}
            type='string'
            label='Vorname'
            onChange={firstNameHandler}
            required={true}
            error={firstNameError}
            helperText={firstNameError ? "Bitte gib deinen Vornamen ein." : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            data-cy='last-name'
            variant='outlined'
            id='last-name'
            value={lastName}
            type='string'
            label='Nachname'
            onChange={lastNameHandler}
            required={true}
            error={lastNameError}
            helperText={lastNameError ? "Bitte gib deinen Nachnamen ein." : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            data-cy='email'
            variant='outlined'
            id='email'
            value={email}
            type='string'
            label='Email'
            onChange={emailHandler}
            required={true}
            error={emailError}
            helperText={emailError ? "Bitte gib eine gültige E-Mail-Adresse ein." : undefined}
          />
        </FormControl>
        <FormControl>
          <GermanPhoneInput
            fullWidth
            data-cy='telephone'
            variant='outlined'
            id='telephone'
            value={telephone}
            type='string'
            label='Handynummer'
            onChange={telephoneHandler}
            required={true}
            error={telephoneError}
            helperText={telephoneError ? "Bitte gib eine gültige Handynummer ein." : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <ShowPasswordToggle sx={{ paddingBottom: 1 }} isShowing={showPassword} onClick={toggleShowPassword} />
          <TextField
            data-cy='password'
            variant='outlined'
            id='password'
            value={password}
            type={showPassword ? "text" : "password"}
            label='Passwort'
            onChange={passwordHandler}
            required={true}
            error={passwordError}
            helperText={passwordError ? "Bitte überprüfe den Wert deines Passworts." : undefined}
          />
          <PasswordValidityTooltips validation={passwordValidity} />
        </FormControl>
        <FormControlLabel
          control={<Checkbox required={true} data-cy='terms-agreement-checkbox' />}
          label={
            <>
              Ich akzeptiere die{" "}
              <Link
                target='_blank'
                to='https://app.novo.eco/AGB_NOVO_Terms_of_service_DE.pdf'
                style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }}
              >
                AGB
              </Link>{" "}
              und{" "}
              <Link target='_blank' to='https://novo.eco/data_protection' style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }}>
                Datenschutzbestimmungen.
              </Link>
            </>
          }
        />
        <Box paddingTop={4} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 250 }}
            data-cy='create-account-button'
            loading={isLoading}
            variant={"contained"}
            label='Account erstellen'
            onClick={(e) => createCustomerAccount(e)}
          />
        </Box>
        {error && (
          <Alert data-cy='signup-error-alert' sx={{ marginTop: 2 }} severity='error'>
            {authErrorTypeToErrorText(error.type)}
          </Alert>
        )}
      </Stack>
    </AuthContainer>
  );
}

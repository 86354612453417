import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { UserResponse } from "../types/cockpit/types";
import { isAdmin, isBankManager, isConsultant } from "../utils/utils";

export const useNavigateToHomeByUserRole = () => {
  const navigate = useNavigate();

  const navigateToHome = useCallback(
    (user?: UserResponse) => {
      navigate(routeOnAnchorParams(user));
    },
    [navigate],
  );

  return navigateToHome;
};

export const routeOnAnchorParams = (user?: UserResponse) => {
  if (isAdmin(user)) {
    return "/cockpit/metrics";
  } else if (isConsultant(user) || isBankManager(user)) {
    return "/cockpit/leads";
  }
  return "/signin";
};

import Stack from "@mui/material/Stack";
import { RowProps } from "../LeadList";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { IsfpState, Product } from "../../../../../types/cockpit/types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import ProgressDial from "./ProgressDial";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import { DisplayBubbleFlowSchnellcheckDialog } from "../DisplayBubbleFlowSchnellcheckDialog";
import { useEffect, useState } from "react";
import { DisplayBubbleFlowRenovationDialog } from "../DisplayBubbleFlowRenovationDialog";
import { DisplaySchnellcheckDialog } from "../DisplaySchnellcheckDialog";
import { DisplayEmbeddedDialog } from "../DisplayEmbeddedDialog";
import { SchnellcheckSource } from "../../../../../types/Schnellcheck";
import { useFetchIsfpDocumentUrl } from "../../../../../hooks/useFetchIsfpDocument";
import Grid from "@mui/material/Grid2";

export default function IsfpWorkflow({ row, openInviteLeadModal }: { row: RowProps; openInviteLeadModal: (product: Product) => void }) {
  const renderStates = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-end"}>
        <Stack>
          <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Aktueller Status</Typography>
          <Chip label={IsfpState.toString(row.isfp.state)} />
        </Stack>
        {![IsfpState.FINAL, IsfpState.OFFER_REJECTED].includes(row.isfp.state) && <NavigateNextIcon sx={{ alignSelf: "flex-end", mb: "0.25rem" }} />}
        {![IsfpState.FINAL, IsfpState.OFFER_REJECTED].includes(row.isfp.state) && (
          <Stack>
            <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Nächster Schritt</Typography>
            {IsfpState.happyPathNext(row.isfp.state) && (
              <Chip variant='outlined' sx={{ color: "text.secondary" }} label={IsfpState.toString(IsfpState.happyPathNext(row.isfp.state)!)} />
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  if (row.isfp.state === IsfpState.START) {
    return (
      <Grid container alignItems={"flex-end"}>
        <Grid size={1}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "fontWeightBold",
              width: "12rem",
              alignSelf: "center",
            }}
          >
            iSFP
          </Typography>
        </Grid>
        <Grid size={3}>
          <Button
            variant='contained'
            sx={{
              fontSize: "0.7rem",
            }}
            onClick={() => openInviteLeadModal(Product.isfp)}
          >
            {IsfpState.toString(row.isfp.state)}
          </Button>
        </Grid>
        <Grid size={8}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              color: "text.secondary",
            }}
          >
            Lade hier deine Kunden zum kostenlosen Schnellcheck, der Vorstufe zum Sanierungsfahrplan ein. Die Kunden können nach dem Ausfüllen des Checks ein
            kostenloses Erstgespräch vereinbaren und ein unverbindliches Angebot anfragen.
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Stack direction={"row"} spacing={5} alignItems={"flex-end"}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "12rem",
            alignSelf: "baseline",
          }}
        >
          iSFP
        </Typography>
        {[IsfpState.FINAL, IsfpState.OFFER_REJECTED].includes(row.isfp.state) && <ProgressDial progress={100} />}
        {![IsfpState.FINAL, IsfpState.OFFER_REJECTED].includes(row.isfp.state) && (
          <ProgressDial progress={(100 * row.isfp.state) / (IsfpState.all().length - 1)} />
        )}
        {renderStates()}
        <IsfpAction row={row} />
      </Stack>
    );
  }
}

function IsfpAction({ row }: { row: RowProps }) {
  const [isSchnellcheckOpen, setSchnellcheckOpen] = useState(false);
  const [isRenovationsOpen, setRenovationsOpen] = useState(false);
  const { fetchIsfpDocumentUrl, isfpDocumentLink, fetchDocumentRunning } = useFetchIsfpDocumentUrl();

  const openPdfDoc = () => {
    if (fetchDocumentRunning || !row.isfp.id) {
      return;
    }
    fetchIsfpDocumentUrl(row.isfp.id);
  };

  useEffect(() => {
    if (isfpDocumentLink) {
      window.location.href = isfpDocumentLink.url;
    }
  }, [isfpDocumentLink]);

  const toggleSchnellcheckModal = () => {
    setSchnellcheckOpen(!isSchnellcheckOpen);
  };

  const toggleRenovationsModal = () => {
    setRenovationsOpen(!isRenovationsOpen);
  };

  if ([IsfpState.STATUS_QUO_FINISHED].includes(row.isfp.state)) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={toggleSchnellcheckModal}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        {row.isfp.bubbleFlow && (
          <DisplayBubbleFlowSchnellcheckDialog bubbleFlow={row.isfp.bubbleFlow} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
        )}
        {row.isfp.schnellcheck && row.isfp.schnellcheck.source === SchnellcheckSource.standalone && (
          <DisplaySchnellcheckDialog schnellcheck={row.isfp.schnellcheck} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
        )}
        {row.isfp.schnellcheck && row.isfp.schnellcheck.source === SchnellcheckSource.embedded && (
          <DisplayEmbeddedDialog schnellcheck={row.isfp.schnellcheck} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
        )}
      </Box>
    );
  } else if ([IsfpState.RENOVATIONS_FINISHED].includes(row.isfp.state)) {
    return (
      <Box>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={toggleRenovationsModal}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        <DisplayBubbleFlowRenovationDialog bubbleFlow={row.isfp.bubbleFlow} open={isRenovationsOpen} handleClose={toggleRenovationsModal} />
      </Box>
    );
  } else if (row.isfp.state === IsfpState.FINAL && row.isfp.id) {
    return (
      <Link
        underline={"always"}
        onClick={openPdfDoc}
        sx={{
          cursor: "pointer",
          color: "text.primary",
          fontSize: "0.9rem",
          textDecorationColor: "inherit",
        }}
      >
        <PictureAsPdfIcon
          sx={{
            fontSize: "1.2rem",
            verticalAlign: "middle",
          }}
        />
        iSFP einsehen
      </Link>
    );
  } else {
    return <></>;
  }
}

import { Alert, Box, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Contexts";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { ActionType, authErrorTypeToErrorText } from "../../hooks";
import { useNavigateToHomeByUserRole } from "../../hooks/useNavigateToHomeByUserRole";
import ActionContainer from "../../components/ActionContainer";
import { GermanPhoneInput } from "./GermanPhoneInput";
import { PasswordValidityTooltips } from "./PasswordValidityTooltips";
import PasswordsMatchTooltip from "./PasswordsMatchTooltip";
import { ShowPasswordToggle } from "./ShowPasswordToggle";
import { PasswordValidationResult, passwordValidator } from "./passwordValidator";
import { phoneNumberIsValid } from "./phoneNumberValidator";

const attributeLabels: { [key: string]: string } = {
  phone_number: "Handynummer",
};

const attributeValidator: { [key: string]: (arg0: string) => boolean } = {
  phone_number: phoneNumberIsValid,
};

const attributeInitialValue: { [key: string]: string } = {
  phone_number: "+49",
};

export type PasswordChangeState = {
  newPassword: string;
  newPasswordRepeat: string;
  newPasswordError: boolean;
  newPasswordRepeatError: boolean;

  requiredAttributeControlsValues: {
    [attributeName: string]: string;
  };
  requiredAttributeControlErrors: {
    [attributeName: string]: boolean;
  };
};

export default function PasswordChangeNeeded() {
  const navigate = useNavigate();
  const navigateHome = useNavigateToHomeByUserRole();
  const { isLoading, error, user, actionNeeded, updatePasswordOnRequireChange } = useContext(AuthContext);
  if (!actionNeeded) {
    navigate("/");
  }
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);

  const [state, setState] = useState<PasswordChangeState>({
    newPassword: "",
    newPasswordError: false,
    newPasswordRepeat: "",
    newPasswordRepeatError: false,
    requiredAttributeControlErrors: {},
    requiredAttributeControlsValues: {},
  });
  const [passwordValidity, setPasswordValidity] = useState<PasswordValidationResult>({
    hasLowerCase: false,
    hasMinLength: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperCase: false,
    passwordsMatch: true,
    passwordHasValue: false,
  });

  useEffect(() => {
    if (actionNeeded?.actionType == ActionType.PASSWORD_CHANGE_REQUIRED) {
      setState({
        ...state,
        requiredAttributeControlErrors: {
          ...actionNeeded.requiredAttributes.map((attrName) => ({ [attrName]: false })).reduce((a, b) => ({ ...a, ...b }), {}),
        },
        requiredAttributeControlsValues: {
          ...actionNeeded.requiredAttributes.map((attrName) => ({ [attrName]: attributeInitialValue[attrName] ?? "" })).reduce((a, b) => ({ ...a, ...b }), {}),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionNeeded]);

  useEffect(() => {
    if (user) {
      navigateHome(user);
    }
  }, [navigateHome, user]);

  useEffect(() => {
    setPasswordValidity(passwordValidator(state.newPassword ?? "", state.newPasswordRepeat ?? ""));
  }, [state.newPassword, state.newPasswordRepeat]);

  function newPasswordChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setState({ ...state, newPassword: event.target.value, newPasswordError: false });
  }
  function newPasswordRepeatChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setState({ ...state, newPasswordRepeat: event.target.value, newPasswordRepeatError: false });
  }

  function additionalAttrInputChangeHandler(additionalAttrName: string) {
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({
        ...state,
        requiredAttributeControlErrors: { ...state.requiredAttributeControlErrors, [additionalAttrName]: false },
        requiredAttributeControlsValues: { ...state.requiredAttributeControlsValues, [additionalAttrName]: event.target.value },
      });
    };
  }

  function onSubmitClick(): void {
    if (
      !state.newPassword ||
      !passwordValidity.hasLowerCase ||
      !passwordValidity.hasUpperCase ||
      !passwordValidity.hasMinLength ||
      !passwordValidity.hasNumber ||
      !passwordValidity.hasSpecialChar
    ) {
      setState({ ...state, newPasswordError: true });
      return;
    }
    if (!state.newPasswordRepeat || !passwordValidity.passwordsMatch) {
      setState({ ...state, newPasswordRepeatError: true });
      return;
    }

    let requiredAttributes: { [attributeName: string]: string } = {};
    if (Object.keys(state.requiredAttributeControlsValues).length) {
      for (const attrName of Object.keys(state.requiredAttributeControlsValues)) {
        if (!attributeValidator[attrName](state.requiredAttributeControlsValues![attrName])) {
          setState({ ...state, requiredAttributeControlErrors: { ...state.requiredAttributeControlErrors, [attrName]: true } });
          return;
        }
      }
      requiredAttributes = state.requiredAttributeControlsValues;
    }

    updatePasswordOnRequireChange(state.newPassword, requiredAttributes);
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowPasswordRetype = () => {
    setShowPasswordRetype(!showPasswordRetype);
  };

  return (
    <ActionContainer>
      <Typography variant='h4' fontWeight={600}>
        Aktualisiere dein Passwort
      </Typography>
      <Typography paddingTop={4}>Aus Sicherheitsgründen muss dein Passwort geändert werden.</Typography>
      <Typography>Bitte gib dein neues Passwort ein.</Typography>
      <Stack paddingTop={4}>
        <ShowPasswordToggle sx={{ paddingBottom: 1 }} isShowing={showPassword} onClick={toggleShowPassword} />
        <Box display={"flex"}>
          <TextField
            fullWidth
            label='Passwort'
            variant='outlined'
            data-cy='password-input'
            id='password-input'
            value={state.newPassword}
            type={showPassword ? "text" : "password"}
            helperText={state.newPasswordError ? "Bitte prüfe dein Passwort" : undefined}
            error={!!state.newPasswordError}
            onChange={newPasswordChangeHandler}
            required={true}
          />
        </Box>
        <PasswordValidityTooltips validation={passwordValidity} />
        <ShowPasswordToggle sx={{ paddingTop: 2, paddingBottom: 1 }} isShowing={showPasswordRetype} onClick={toggleShowPasswordRetype} />
        <Box display={"flex"}>
          <TextField
            fullWidth
            label='Passwort wiederholen'
            variant='outlined'
            data-cy='password-input-retype'
            id='password-input-retype'
            value={state.newPasswordRepeat}
            type={showPasswordRetype ? "text" : "password"}
            onChange={newPasswordRepeatChangeHandler}
            error={state.newPasswordRepeatError}
            helperText={state.newPasswordRepeatError ? "Bitte prüfe dein Passwort" : undefined}
            required={true}
          />
        </Box>
        <PasswordsMatchTooltip {...passwordValidity} />
        {Object.keys(state.requiredAttributeControlsValues).map((additionalAttrName) => {
          return (
            <Box key={additionalAttrName} paddingTop={2} display={"flex"}>
              <AdditionalAttrInput
                attributeName={additionalAttrName}
                fullWidth
                label={attributeLabels[additionalAttrName] ?? additionalAttrName}
                variant='outlined'
                data-cy={`${additionalAttrName}-input`}
                id={`${additionalAttrName}-input`}
                value={state.requiredAttributeControlsValues![additionalAttrName]}
                type='text'
                onChange={additionalAttrInputChangeHandler(additionalAttrName)}
                error={state.requiredAttributeControlErrors![additionalAttrName]}
                helperText={
                  state.requiredAttributeControlErrors![additionalAttrName]
                    ? `Bitte prüfe den Wert für ${attributeLabels[additionalAttrName] ?? additionalAttrName}`
                    : undefined
                }
                required={true}
              />
            </Box>
          );
        })}
        <Box paddingTop={4} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='sign-in-btn'
            loading={isLoading}
            onClick={onSubmitClick}
            label='Aktualisieren'
            size='large'
            variant={"contained"}
            disabled={isLoading}
          />
        </Box>
        {error && (
          <Alert data-cy='change-error-alert' sx={{ marginTop: 2 }} severity='error'>
            {authErrorTypeToErrorText(error.type)}
          </Alert>
        )}
      </Stack>
    </ActionContainer>
  );
}

type AdditionalAttrTextFieldProps = { attributeName: string } & TextFieldProps;

const AdditionalAttrInput: React.FC<AdditionalAttrTextFieldProps> = ({ attributeName, ...props }: AdditionalAttrTextFieldProps) => {
  if (attributeName == "phone_number") {
    return <GermanPhoneInput {...props} />;
  }
  return <TextField {...props} />;
};

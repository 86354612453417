import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useEffect, useState } from "react";
import { Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, Fade, Alert, DialogActions } from "@mui/material";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import useInviteConsultantToCockpit from "../../../hooks/useConsultantInvite";
import { isValidEmail } from "../../auth/emailValidator";
import useAuth from "../../../hooks/useAuth";
import { ConsultantInvitationResponseCodes } from "../../../types/cockpit/types";

const responseCodeToMessage = (code: ConsultantInvitationResponseCodes): string => {
  switch (code) {
    case ConsultantInvitationResponseCodes.MISSING_INVITATION_EMAIL:
      return "Die Berater-E-Mail fehlt";
    case ConsultantInvitationResponseCodes.INVALID_INVITATION_DOMAIN:
      return "Die E-Mail-Domäne des Beraters gehört nicht zu Ihrer Organisation";
    case ConsultantInvitationResponseCodes.INVITED_BY_ANOTHER_MANAGER:
      return "Der Berater wurde bereits von einem anderen Manager eingeladen";
    case ConsultantInvitationResponseCodes.ADDED_TO_MANAGER:
      return "Der Berater hat bereits ein Konto und wurde Ihrem Team hinzugefügt";
    case ConsultantInvitationResponseCodes.ALREADY_INVITED_BY_MANAGER:
      return "Sie haben diesen Berater bereits eingeladen";
    case ConsultantInvitationResponseCodes.SUCCESS:
      return "Erfolg!";
    default:
      return "Wir haben eine unbekannte Antwort erhalten";
  }
};

export const InviteConsultantToCockpitDialog = ({ onClose }: { onClose: () => void }) => {
  const session = useAuth();
  const [email, setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const { inviteConsultantToCockpit, error, sendingInvite, inviteResponse } = useInviteConsultantToCockpit();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>();

  const handleClose = () => {
    onClose();
    setEmail("");
    setEmailError("");
    setErrorMessage("");
    setSuccessMessage("");
  };

  const validate = () => {
    if (!email) {
      setEmailError("Pflichtfeld");
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError("Ungültige Email Adresse");
      return false;
    } else if (email.split("@")[1] !== session.user?.tenant?.emailDomain) {
      setEmailError(`Die E-Mail-Adresse muss zur Domäne ${session.user?.tenant?.emailDomain} gehören`);
      return false;
    } else {
      setEmailError(undefined);
    }
    return true;
  };

  const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setSuccessMessage("");
    if (!validate()) {
      return;
    }
    setErrorMessage("");
    inviteConsultantToCockpit(email!);
  };

  useEffect(() => {
    if (error) {
      const message = error.response?.data?.code
        ? responseCodeToMessage(error.response.data.code)
        : "Es ist ein Fehler aufgetreten. Wir wurden bereits benachrichtigt und werden Sie in Kürze kontaktieren";
      setErrorMessage(message);
    } else if (inviteResponse) {
      const message = responseCodeToMessage(inviteResponse.code);
      setSuccessMessage(message);
      setEmail("");
    }
  }, [inviteResponse, error]);

  return (
    <Dialog onClose={handleClose} open={true}>
      <Box sx={{ p: 2 }}>
        <DialogTitle>
          <span style={{ maxWidth: "80%", display: "inline-block" }}>Neues Teammitglied einladen</span>
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>Gib die E-Mail Adresse des Teammitglieds an, das du einladen möchtest.</DialogContentText>
            <TextField
              variant='outlined'
              id='email-input'
              data-cy='email-input'
              value={email}
              type='email'
              inputMode='email'
              label='Email Adresse'
              onChange={updateEmail}
              required={true}
              error={!!emailError}
              helperText={emailError}
            />
            <Fade in={!!successMessage} timeout={500} unmountOnExit>
              <Alert sx={{ marginTop: 2 }} severity='success'>
                {successMessage}
              </Alert>
            </Fade>
            <Fade in={!!errorMessage} timeout={500} unmountOnExit>
              <Alert sx={{ marginTop: 2 }} severity='error'>
                {errorMessage}
              </Alert>
            </Fade>
            <DialogActions sx={{ p: 3 }}>
              <ButtonWithSpinner variant={"contained"} loading={sendingInvite} label='Einladen' onClick={handleSubmit} />
            </DialogActions>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { ErrorControlState, useFormErrorControl } from "../../../forms/errorControls";
import { useFinalizeConsumptionCert } from "../../../hooks/useFinalizeConsumptionCert";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { FinalizeCertItem } from "./ConsumptionCertificateList";

type DibtAuth = {
  username?: string;
  password?: string;
};

type ConsumptionCertificateDialogProps = {
  isOpen: boolean;
  closeHandler: () => void;
  certificate: FinalizeCertItem | undefined;
};

export default function ConsumptionCertificateDialog({ isOpen, closeHandler, certificate }: ConsumptionCertificateDialogProps) {
  const [dibtAuth, setDibtAuth] = useState<DibtAuth>({ password: "", username: "" });
  const { postFinalizeCert, finalizeCertError, finalizeCertIsRunning, finalizeCertResponseStatus, setFinalizeCertError } = useFinalizeConsumptionCert();

  const close = useCallback(() => {
    setDibtAuth({ password: "", username: "" });
    setFinalizeCertError(undefined);
    closeHandler();
  }, [setDibtAuth, setFinalizeCertError, closeHandler]);

  useEffect(() => {
    if (finalizeCertResponseStatus === 200 && isOpen) {
      setTimeout(() => {
        close();
      }, 2000);
    }
  }, [close, finalizeCertResponseStatus, isOpen]);

  const errorControls: { [key in keyof DibtAuth]?: ErrorControlState<string> } = {
    username: useFormErrorControl((val?: string) => !!val && /.+/.test(val), "Benutzername eingeben"),
    password: useFormErrorControl((val?: string) => !!val && /.+/.test(val), "Passwort eingeben"),
  };

  const setFieldValidity = (fieldName: keyof DibtAuth, fieldValue?: string) => {
    if (errorControls[fieldName]) {
      const errorCtrl = errorControls[fieldName];
      const isValid = errorCtrl?.isValid(fieldValue);
      errorCtrl?.setIsError(!isValid);
      errorCtrl?.setErrorMsg(isValid ? "" : errorCtrl.errorMsg);
    }
  };

  const handleFieldChange = (fieldName: keyof DibtAuth) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setFieldValidity(fieldName, event.target.value);
    setDibtAuth({ ...dibtAuth, [fieldName]: event.target.value });
  };

  const handleFinalizeCertConfirmClick = () => {
    // perform a validation check against fields
    let allValid = true;
    for (const fieldName of Object.keys(errorControls)) {
      const typedFieldName: keyof DibtAuth = fieldName as keyof DibtAuth;
      const ctrl = errorControls[typedFieldName];
      const fieldValue = dibtAuth[typedFieldName];
      const isValid = ctrl?.isValid(fieldValue);
      // toggle all valid state if one of them is not valid
      allValid = !isValid ? false : allValid;

      setFieldValidity(typedFieldName, fieldValue);
    }
    setFinalizeCertError(undefined);
    if (allValid && certificate && dibtAuth.username && dibtAuth.password) {
      postFinalizeCert({
        certificateId: certificate.id,
        dibtUser: dibtAuth.username,
        dibtPassword: dibtAuth.password,
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={close} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{"Verbrauchsausweis abschließen und hochladen?"}</DialogTitle>
      <DialogContent>
        <Stack>
          <DialogContentText pb={2} id='alert-dialog-description'>
            Der Verbrauchsausweis für `&lsquo;`{certificate?.address}`&rsquo;` wird generiert und beim DIBt mit einer Registriernummer gespeichert. Nach der
            Generierung sind keine weiteren Änderungen möglich.
          </DialogContentText>
          <Divider />
          <DialogContentText py={2}>Authentifiziere dich mit deinem DIBt Account:</DialogContentText>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  disabled={finalizeCertIsRunning}
                  error={errorControls.username?.isErrorState}
                  helperText={errorControls.username?.errorMsgState}
                  label='DIBt Benutzername'
                  id='username'
                  value={dibtAuth?.username}
                  onChange={handleFieldChange("username")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  disabled={finalizeCertIsRunning}
                  error={errorControls.password?.isErrorState}
                  helperText={errorControls.password?.errorMsgState}
                  label='DIBt Passwort'
                  id='password'
                  value={dibtAuth?.password}
                  onChange={handleFieldChange("password")}
                  type='password'
                />
              </FormControl>
            </Grid>
          </Grid>
          {finalizeCertResponseStatus === 200 && (
            <Alert sx={{ marginTop: 2 }} severity='success'>
              Erfolg! Aktualisieren Sie die Seite in ein paar Minuten.
            </Alert>
          )}
          {finalizeCertError && (
            <Alert sx={{ marginTop: 2 }} severity='error'>
              Fehler bei der Erstellung des Verbrauchsausweises.
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={finalizeCertIsRunning} onClick={close}>
          Abbrechen
        </Button>
        <ButtonWithSpinner loading={finalizeCertIsRunning} label='Bestätigen' variant={"contained"} onClick={handleFinalizeCertConfirmClick} />
      </DialogActions>
    </Dialog>
  );
}

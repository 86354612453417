import LaunchIcon from "@mui/icons-material/Launch";
import { Button, Card, CardContent, CardHeader, Divider, Grid2, Link as MuiLink, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SlowLoadingImage } from "../../../components/common/NovoSlowLoadingImage";
import { AuthContext } from "../../../Contexts";
import useImage from "../../../hooks/useImage";
import { isBankManager, isConsultant } from "../../../utils/utils";
import { RUN_COCKPIT_TOUR_SEARCH_ARG } from "../CockpitTour";

type WorkshopData = {
  copy: string;
  link: string;
  linkCopy: string;
};

export function ContentsList() {
  return (
    <Box sx={{ flexGrow: 7, minHeight: 800, width: "100%", pt: 2 }}>
      <Stack spacing={2}>
        <Typography variant='h5' style={{ fontWeight: 600 }}>
          Inhalte
        </Typography>
        <Grid2 container spacing={{ xs: 2 }}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TrainingsSection />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <VideosAndManuals />
          </Grid2>
        </Grid2>
      </Stack>
    </Box>
  );
}

const TrainingsSection = () => {
  return (
    <Card sx={{ padding: 2 }} elevation={0}>
      <CardHeader title='Trainings' />
      <CardContent>
        <Stack divider={<Divider />} spacing={6}>
          <WorkshopLink />
          <CockpitTourRedo />
        </Stack>
      </CardContent>
    </Card>
  );
};

const WorkshopLink = () => {
  const { user } = useContext(AuthContext);
  const sophia = useImage("sophia-avatar.png");
  const [cockpitWorkshopData, setCockpitWorkshopData] = useState<WorkshopData>();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isBankManager(user)) {
      setCockpitWorkshopData({
        copy: "Möchtest du erfahren, wie du mit Hilfe von NOVO dein Team ins Rollen bringst? Hol’ dir Best Practices im Workshop für Bezirksdirektoren!",
        link: "https://calendly.com/sophia-buildingnovo/erfolgstraining-fur-bezirksdirektoren",
        linkCopy: "Hier Bezirksdirektoren-Workshop buchen",
      });
    } else if (isConsultant(user)) {
      setCockpitWorkshopData({
        copy: "Möchtest du erfahren, wie du mit Hilfe von NOVO erfolgreich Abschlüsse für Sanierungsfinanzierungen machst? Dann buche dich zu unserem Erfolgstraining mit unserem Team ein.",
        link: "https://calendly.com/sophia-buildingnovo/erfolgstraining-fuer-bezirksleiter",
        linkCopy: "Hier Bezirksleiter-Training buchen",
      });
    }
  }, [user]);

  if (!cockpitWorkshopData) {
    return <></>;
  }

  return (
    <Grid2 container>
      <Grid2 size={9}>
        <Typography paddingBottom={4}>{cockpitWorkshopData.copy}</Typography>
        <Box paddingBottom={2}>
          <MuiLink
            underline={"always"}
            sx={{
              color: "text.primary",
              textDecorationColor: "inherit",
            }}
            aria-label={cockpitWorkshopData.linkCopy}
            component={Link}
            target='_blank'
            to={cockpitWorkshopData.link}
          >
            {cockpitWorkshopData.linkCopy}{" "}
            <LaunchIcon
              sx={{
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          </MuiLink>
        </Box>
      </Grid2>
      <Grid2 flexGrow={1} />
      <Grid2 width={64}>
        <Box
          sx={{
            backgroundImage: `url('${sophia.image}')`,
            backgroundSize: "cover",
            borderRadius: "50%",
            height: 64,
            width: 64,
          }}
        />
      </Grid2>
    </Grid2>
  );
};

const CockpitTourRedo = () => {
  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <Typography paddingBottom={2}>Wiederhole die Cockpit-Tour, um mehr über die wichtigsten Funktionen zu erfahren.</Typography>
      <Box>
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => {
            navigate(`/cockpit/leads?${RUN_COCKPIT_TOUR_SEARCH_ARG}=true`);
          }}
        >
          Cockpit-tour wiederholen
        </Button>
      </Box>
    </Stack>
  );
};

function VideosAndManuals() {
  return (
    <Card sx={{ padding: 2 }} elevation={0}>
      <CardHeader title='Videos & Anleitungen' />
      <CardContent>
        <Stack spacing={6}>
          <LeadsExpandVideo />
        </Stack>
      </CardContent>
    </Card>
  );
}

const LeadsExpandVideo = () => {
  return (
    <Stack>
      <Typography paddingBottom={2}>
        In deiner Kundenliste kannst du auf die Zeilen klicken, um detaillierte Informationen abzurufen und Dokumente herunterzuladen.
      </Typography>
      <SlowLoadingImage assetPath='cockpit-tour/lead_view_cert_open.gif' />
    </Stack>
  );
};

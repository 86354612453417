import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import QRCode from "react-qr-code";
import InviteNewLeadDialog from "./InviteNewLeadDialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Shortcuts({ consultantCode }: { consultantCode?: string }) {
  const [openNewInvitation, setOpenNewInvitation] = useState<boolean>(false);
  const [openCopyNotification, setOpenCopyNotification] = useState<boolean>(false);
  const [openShowQrCode, setOpenShowQrCode] = useState<boolean>(false);

  const toggleNewInvitation = () => {
    setOpenNewInvitation(!openNewInvitation);
  };

  const handleCopyReferrer = () => {
    navigator.clipboard.writeText(consultantCode!);
    setOpenCopyNotification(true);
  };

  const toggleShowQrCode = () => {
    setOpenShowQrCode(!openShowQrCode);
  };

  const renderConsultantLinkButton = () => {
    if (!consultantCode) {
      return <></>;
    }
    return (
      <Grid container size={12}>
        <Grid size={6}>
          <Button fullWidth variant='outlined' color='secondary' data-cy='personal-link-button' sx={{ fontSize: 12 }} onClick={handleCopyReferrer}>
            Dein persönlicher Link
          </Button>
        </Grid>
        <Grid my={"auto"} size={6}>
          <Typography variant='caption'>Link zum Verschicken an Kunden</Typography>
        </Grid>
        <Snackbar
          message='In Zwischenablage kopiert'
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={2000}
          onClose={() => setOpenCopyNotification(false)}
          open={openCopyNotification}
        />
      </Grid>
    );
  };
  function renderQRCodeButton() {
    if (!consultantCode) {
      return <></>;
    }

    return (
      <Grid container size={12}>
        <Grid size={6}>
          <Button fullWidth variant='outlined' color='secondary' data-cy='qr-code-open-dialog-button' sx={{ fontSize: 12 }} onClick={toggleShowQrCode}>
            QR Code
          </Button>
        </Grid>
        <Grid my={"auto"} size={6}>
          <Typography variant='caption'>QR-Code zum Verschicken oder Drucken</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.default", p: 4 }}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography variant='body2' fontWeight='fontWeightBold'>
            So aktivierst du deine Kunden
          </Typography>
        </Grid>
        <Grid container size={12} data-joyride='lead-invite-button-section'>
          <Grid size={6}>
            <Button
              fullWidth
              data-joyride='lead-invite-button'
              variant='contained'
              data-cy='invite-customer-button'
              sx={{ fontSize: 12 }}
              onClick={toggleNewInvitation}
            >
              Kunden Einladen
            </Button>
          </Grid>
          <Grid my={"auto"} size={6}>
            <Typography variant='caption'>Kunde erhält eine E-mail</Typography>
          </Grid>
        </Grid>
        {renderConsultantLinkButton()}
        {renderQRCodeButton()}
      </Grid>
      <InviteNewLeadDialog open={openNewInvitation} onClose={toggleNewInvitation} />
      <ShowQRCodeDialog open={openShowQrCode} onClose={toggleShowQrCode} link={consultantCode!} />
    </Box>
  );
}

type ShowQRCodeDialogProps = {
  open: boolean;
  link: string;
  onClose: () => void;
};

const ShowQRCodeDialog = ({ open, link, onClose }: ShowQRCodeDialogProps) => {
  const constQRCodeElementId = "QRCodeOutlet";

  const handleClose = () => {
    onClose();
  };

  const onImageDownload = () => {
    const svg = document.getElementById(constQRCodeElementId);
    if (!svg) {
      return;
    }
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  if (!link) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ p: 2 }}>
        <DialogTitle>
          Dein Berater-Empfehlungs-QRCode
          <IconButton sx={{ float: "inline-end", ml: "1rem", top: "-4px" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignItems={"center"}>
            <QRCode id={constQRCodeElementId} level='Q' value={link} />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={onImageDownload} variant='contained'>
            QR Code Herunterladen
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

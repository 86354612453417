import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { apiEndpoint } from "../utils/params";
import { Property } from "../types/types";
import { AuthContext } from "../Contexts";
import { retrieveRum } from "../AwsRum";

const API_ENDPOINT = `${apiEndpoint()}/properties`;

export default function useFetchProperties() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token, logout } = useContext(AuthContext);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const { data } = await axios.get<Property[]>(API_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${token?.id_token}`,
          },
        });
        setProperties([...data]);
        setIsLoading(false);
      } catch (e) {
        if (e.response.status === 401) {
          logout();
        } else {
          retrieveRum()?.recordError(e);
        }
      }
    };
    fetchProperties();
  }, [logout, token]);

  return { properties, isLoading };
}

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { leadErrorCodeToMessage, useInviteLeadProduct } from "../../../../hooks/useLeadInviteHooks";
import { InviteEmailTemplate, Product } from "../../../../types/cockpit/types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useFetchInviteEmailTemplate from "../../../../hooks/useFetchInviteEmailTemplate";
import Grid from "@mui/material/Grid2";
import Voucher from "../Voucher";

interface InviteLeadToProductDialogProps {
  invite: { leadId: string; leadName: string; leadEmail: string; product: Product };
  onClose: () => void;
}

export const InviteLeadToProductDialog = ({ invite, onClose }: InviteLeadToProductDialogProps) => {
  const [free, setFree] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [firstRenderReady, setFirstRenderReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { inviteLeadToProduct, error, isLoading, inviteResponse } = useInviteLeadProduct();
  const { getInviteEmailTemplate, template: fetchedTemplate } = useFetchInviteEmailTemplate();
  const [template, setTemplate] = useState<InviteEmailTemplate | undefined>();

  const productMapping = new Map<Product, string>();
  productMapping.set(Product.isfp, "Schnellcheck");
  productMapping.set(Product.consumptionCertificate, "Energieausweis");
  productMapping.set(Product.onePager, "Sanierungsgutachten");

  useEffect(() => {
    getInviteEmailTemplate(invite.product, free);
  }, [free, getInviteEmailTemplate, invite]);

  useEffect(() => {
    setFirstRenderReady(firstRenderReady || !!fetchedTemplate);
    if (fetchedTemplate) {
      setTemplate(fetchedTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTemplate]);

  const handleFreeChange = (checked: boolean) => {
    setShowSuccess(false);
    setErrorMessage("");
    setFree(checked);
  };

  const handleClose = () => {
    onClose();
    setFree(false);
    setShowSuccess(false);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    if (!invite) {
      return;
    }
    const reqBody = {
      product: invite.product,
      isFree: free,
    };
    setErrorMessage("");

    inviteLeadToProduct(invite.leadId, reqBody);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(leadErrorCodeToMessage(error.response?.data?.code));
    } else if (inviteResponse) {
      setShowSuccess(true);
    }
  }, [inviteResponse, error]);

  return (
    <Dialog open={firstRenderReady} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <Box sx={{ p: 2 }}>
        {invite && (
          <>
            <DialogTitle>
              <span style={{ maxWidth: "80%", display: "inline-block" }}>Kunden zum {productMapping.get(invite.product)} einladen</span>
              <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid size={6}>
                  <Stack spacing={2}>
                    <DialogContentText>
                      Lade {invite.leadName} zum {productMapping.get(invite.product)} ein.&nbsp;
                    </DialogContentText>
                    <Voucher product={invite.product} voucherSelected={handleFreeChange} />
                    <Fade in={showSuccess} timeout={500} unmountOnExit>
                      <Alert sx={{ marginTop: 2 }} severity='success'>
                        Erfolg!
                      </Alert>
                    </Fade>
                    <Fade in={!!errorMessage} timeout={500} unmountOnExit>
                      <Alert sx={{ marginTop: 2 }} severity='error'>
                        {errorMessage}
                      </Alert>
                    </Fade>
                    <DialogActions sx={{ p: 3 }}>
                      <ButtonWithSpinner disabled={!open || showSuccess} variant={"contained"} loading={isLoading} label='Einladen' onClick={handleSubmit} />
                    </DialogActions>
                  </Stack>
                </Grid>
                <Grid size={6}>
                  {template?.emailMessage && <div dangerouslySetInnerHTML={{ __html: template?.emailMessage.replace("{name}", invite.leadName) }} />}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Box>
    </Dialog>
  );
};

import Grid from "@mui/material/Grid2";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import { Button, Grow, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import FormScreen from "../FormScreen";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useImage from "../../../../hooks/useImage";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { BasementOrGroundInformation, HouseConfiguration } from "../../../../types/RenovationQuestionnaire";

export default function FloorsScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [numberOfFloorsError, setNumberOfFloorsError] = useState<string>();
  const [atticHeightError, setAtticHeightError] = useState<string>();
  const [upperFloorHeightError, setUpperFloorHeightError] = useState<string>();
  const [groundFloorHeightError, setGroundFloorHeightError] = useState<string>();
  const [basementHeightError, setBasementHeightError] = useState<string>();
  const [defaultFloorHeight, setDefaultFloorHeight] = useState<string>();
  const [houseConfiguration, setHouseConfiguration] = useState<HouseConfiguration>(renovations.answers?.houseConfiguration || {});
  const [transitioned, setTransitioned] = useState(!!renovations.answers?.houseConfiguration?.groundFloorHeight);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (houseConfiguration.floors && defaultFloorHeight && !transitioned) {
      if (timeoutId) clearTimeout(timeoutId);
      const timeout = setTimeout(() => setTransitioned(true), 800);
      setTimeoutId(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseConfiguration, defaultFloorHeight]);

  const validate = () => {
    if (!houseConfiguration.floors) {
      setNumberOfFloorsError("Pflichtfeld");
      return false;
    } else if (houseConfiguration.floors < 1 || houseConfiguration.floors > 20) {
      setNumberOfFloorsError("Muss zwischen 1 und 20 liegen");
      return false;
    } else {
      setNumberOfFloorsError(undefined);
    }
    if (!houseConfiguration.groundFloorHeight) {
      setGroundFloorHeightError("Pflichtfeld");
      return false;
    } else if (houseConfiguration.groundFloorHeight < 150 || houseConfiguration.groundFloorHeight > 400) {
      setGroundFloorHeightError("Muss zwischen 150cm und 400cm liegen");
      return false;
    } else {
      setGroundFloorHeightError(undefined);
    }
    if (houseConfiguration.floors > 1) {
      if (!houseConfiguration.upperFloorsHeight) {
        setUpperFloorHeightError("Pflichtfeld");
        return false;
      } else if (
        houseConfiguration.upperFloorsHeight !== undefined &&
        (houseConfiguration.upperFloorsHeight < 150 || houseConfiguration.upperFloorsHeight > 400)
      ) {
        setUpperFloorHeightError("Muss zwischen 150cm und 400cm liegen");
        return false;
      } else {
        setUpperFloorHeightError(undefined);
      }
    }
    if (houseConfiguration.atticHeight !== undefined && (houseConfiguration.atticHeight < 150 || houseConfiguration.atticHeight > 400)) {
      setAtticHeightError("Muss zwischen 150cm und 400cm liegen");
      return false;
    } else {
      setAtticHeightError(undefined);
    }
    if (houseConfiguration.basementHeight !== undefined && (houseConfiguration.basementHeight < 150 || houseConfiguration.basementHeight > 400)) {
      setBasementHeightError("Muss zwischen 150cm und 400cm liegen");
      return false;
    } else {
      setBasementHeightError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const basementOrGroundInformation: BasementOrGroundInformation = {};
      if (!houseConfiguration.basementHeight) {
        basementOrGroundInformation.basement = "none";
      }
      const newState = { ...renovations, answers: { ...renovations.answers, houseConfiguration, basementOrGroundInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 2: </strong>Stockwerke und Geschossinformationen
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack direction='row'>
            <TextField
              fullWidth
              value={houseConfiguration.floors || ""}
              variant='outlined'
              id='number-of-floors-input'
              label='Anzahl der Geschosse'
              required={true}
              type='number'
              error={!!numberOfFloorsError}
              helperText={numberOfFloorsError}
              slotProps={{
                htmlInput: { min: 1, max: 20 },
              }}
              onChange={(e) => {
                const floors = +e.target.value;
                const upperFloorsHeight =
                  floors === 1 ? undefined : houseConfiguration.floors === 1 ? houseConfiguration.groundFloorHeight : houseConfiguration.upperFloorsHeight;
                setHouseConfiguration({ ...houseConfiguration, floors, upperFloorsHeight });
                setNumberOfFloorsError(undefined);
              }}
            />
            <Tooltip title='Anzahl der Geschosse ohne Keller und Dachboden. Geben Sie hier die Etagen Ihrer Immobilie inklusive Erdgeschoss an. Keller und Dachboden können Sie im weiteren Verlauf angeben.'>
              <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
            </Tooltip>
          </Stack>
        </Grid>
        {!transitioned && (
          <Grid size={{ xs: 12, md: 6 }}>
            <Stack>
              <TextField
                fullWidth
                value={defaultFloorHeight || ""}
                variant='outlined'
                id='floor-height-input'
                label='Geschosshöhe'
                required={true}
                type='number'
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                  },
                  htmlInput: { min: 150, max: 400 },
                }}
                onChange={(e) => {
                  setHouseConfiguration({
                    ...houseConfiguration,
                    groundFloorHeight: +e.target.value,
                    upperFloorsHeight: houseConfiguration.floors && houseConfiguration.floors > 1 ? +e.target.value : undefined,
                  });
                  setDefaultFloorHeight(e.target.value);
                }}
              />
              <Typography fontSize='0.7rem'>
                Sollte es unterschiedliche Geschosshöhen im Haus geben, können Sie diese im nächsten Schritt noch ändern.
              </Typography>
            </Stack>
          </Grid>
        )}
        {transitioned && (
          <Grid size={12}>
            <FloorsDiagram
              key={defaultFloorHeight}
              houseConfiguration={houseConfiguration}
              setHouseConfiguration={setHouseConfiguration}
              atticHeightError={atticHeightError}
              setAtticHeightError={setAtticHeightError}
              upperFloorHeightError={upperFloorHeightError}
              setUpperFloorHeightError={setUpperFloorHeightError}
              groundFloorHeightError={groundFloorHeightError}
              setGroundFloorHeightError={setGroundFloorHeightError}
              basementHeightError={basementHeightError}
              setBasementHeightError={setBasementHeightError}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      disableNext={!transitioned}
      progressLabel='Stockwerke'
      progressValue={16}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Wir erstellen ein 3D-Modell
      <br />
      Ihres Hauses, um unter anderem
      <br />
      das Luftvolumen korrekt zu erfassen.
      <br />
      Diese Daten lassen sich in der Regel
      <br />
      nicht aus Grundrissen entnehmen.
      <br />
      <br />
      Mit Ihren Angaben ermöglichen Sie
      <br />
      uns eine sehr präzise Simulation
      <br />
      Ihres Gebäudes.
    </Typography>
  );
}

function FloorsDiagram({
  houseConfiguration,
  setHouseConfiguration,
  atticHeightError,
  setAtticHeightError,
  upperFloorHeightError,
  setUpperFloorHeightError,
  groundFloorHeightError,
  setGroundFloorHeightError,
  basementHeightError,
  setBasementHeightError,
}: {
  houseConfiguration: HouseConfiguration;
  setHouseConfiguration: Dispatch<SetStateAction<HouseConfiguration>>;
  atticHeightError?: string;
  setAtticHeightError?: Dispatch<SetStateAction<string | undefined>>;
  upperFloorHeightError?: string;
  setUpperFloorHeightError?: Dispatch<SetStateAction<string | undefined>>;
  groundFloorHeightError?: string;
  setGroundFloorHeightError?: Dispatch<SetStateAction<string | undefined>>;
  basementHeightError?: string;
  setBasementHeightError?: Dispatch<SetStateAction<string | undefined>>;
}) {
  const roof = useImage("House_sections_roof.png");
  const attic = useImage("House_sections_attic.png");
  const upperFloors = useImage("House_sections_upper_floors.png");
  const groundFloor = useImage("House_sections_ground_floor.png");
  const ground = useImage("House_sections_ground.png");
  const basement = useImage("House_sections_basement.png");

  const storeys = houseConfiguration.floors!;
  const hasAttic = houseConfiguration.atticHeight !== undefined;
  const hasBasement = houseConfiguration.basementHeight !== undefined;

  const toggleAttic = () => {
    if (houseConfiguration.atticHeight) {
      setHouseConfiguration({ ...houseConfiguration, atticHeight: undefined });
      setAtticHeightError?.(undefined);
    } else {
      setHouseConfiguration({ ...houseConfiguration, atticHeight: houseConfiguration.upperFloorsHeight || houseConfiguration.groundFloorHeight });
    }
  };
  const toggleBasement = () => {
    if (houseConfiguration.basementHeight) {
      setHouseConfiguration({ ...houseConfiguration, basementHeight: undefined });
      setBasementHeightError?.(undefined);
    } else {
      setHouseConfiguration({ ...houseConfiguration, basementHeight: houseConfiguration.upperFloorsHeight || houseConfiguration.groundFloorHeight });
    }
  };
  return (
    <Stack p={2}>
      <Grid container alignItems='center' justifyContent='center'>
        {!hasAttic && (
          <>
            <Grid size={6}>
              <Grow in={!hasAttic} timeout={800}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={roof.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={!hasAttic} timeout={800}>
                <Button variant='outlined' color='secondary' onClick={toggleAttic}>
                  + Dachboden
                </Button>
              </Grow>
            </Grid>
          </>
        )}
        {hasAttic && (
          <>
            <Grid size={6}>
              <Grow in={hasAttic} timeout={400}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={attic.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={hasAttic} timeout={400}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <TextField
                    fullWidth
                    value={houseConfiguration.atticHeight || ""}
                    variant='outlined'
                    id='attic-height-input'
                    label='Höhe'
                    required={true}
                    type='number'
                    error={!!atticHeightError}
                    helperText={atticHeightError}
                    onChange={(e) => {
                      setHouseConfiguration({ ...houseConfiguration, atticHeight: +e.target.value });
                      setAtticHeightError?.(undefined);
                    }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                      },
                      htmlInput: { min: 150, max: 400 },
                    }}
                  />
                  <IconButton color='secondary' onClick={toggleAttic}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
        {storeys > 1 && (
          <>
            <Grid size={6}>
              <Grow in={storeys > 1} timeout={800}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={upperFloors.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={storeys > 1} timeout={800}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <TextField
                    fullWidth
                    value={houseConfiguration.upperFloorsHeight || ""}
                    variant='outlined'
                    id='upper-floors-height-input'
                    label='Höhe'
                    required={true}
                    type='number'
                    error={!!upperFloorHeightError}
                    helperText={upperFloorHeightError}
                    onChange={(e) => {
                      setHouseConfiguration({ ...houseConfiguration, upperFloorsHeight: +e.target.value });
                      setUpperFloorHeightError?.(undefined);
                    }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                      },
                      htmlInput: { min: 150, max: 400 },
                    }}
                  />
                  <div style={{ width: 46 }} />
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
        <Grid size={6}>
          <Grow in timeout={800}>
            <Stack alignItems='center' flexGrow={1}>
              <img src={groundFloor.image} height={69} style={{ alignSelf: "center" }} />
            </Stack>
          </Grow>
        </Grid>
        <Grid size={6}>
          <Grow in timeout={800}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <TextField
                fullWidth
                value={houseConfiguration.groundFloorHeight || ""}
                variant='outlined'
                id='ground-floor-height-input'
                label='Höhe'
                required={true}
                type='number'
                error={!!groundFloorHeightError}
                helperText={groundFloorHeightError}
                onChange={(e) => {
                  setHouseConfiguration({ ...houseConfiguration, groundFloorHeight: +e.target.value });
                  setGroundFloorHeightError?.(undefined);
                }}
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                  },
                  htmlInput: { min: 150, max: 400 },
                }}
              />
              <div style={{ width: 46 }} />
            </Stack>
          </Grow>
        </Grid>
        {!hasBasement && (
          <>
            <Grid size={6}>
              <Grow in={!hasBasement} timeout={800}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={ground.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={!hasBasement} timeout={800}>
                <Button variant='outlined' color='secondary' onClick={toggleBasement}>
                  + Keller
                </Button>
              </Grow>
            </Grid>
          </>
        )}
        {hasBasement && (
          <>
            <Grid size={6}>
              <Grow in={hasBasement} timeout={400}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={basement.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={hasBasement} timeout={400}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <TextField
                    fullWidth
                    value={houseConfiguration.basementHeight || ""}
                    variant='outlined'
                    id='basement-height-input'
                    label='Höhe'
                    required={true}
                    type='number'
                    error={!!basementHeightError}
                    helperText={basementHeightError}
                    onChange={(e) => {
                      setHouseConfiguration({ ...houseConfiguration, basementHeight: +e.target.value });
                      setBasementHeightError?.(undefined);
                    }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                      },
                      htmlInput: { min: 150, max: 400 },
                    }}
                  />
                  <IconButton color='secondary' onClick={toggleBasement}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}

import Grid from "@mui/material/Grid2";
import ProgressBar from "../ProgressBar";
import { Button, Stack, Typography } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export default function IntroScreen({ next }: RenovationsScreenProps) {
  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={"Jetzt starten!"} value={0} />
      </Grid>
      <Grid size={12}>
        <Stack mx={5}>
          <Typography>
            Um Sie bei der Planung Ihrer Sanierung bestmöglich zu unterstützen, werden wir diese Informationen so genau und umfassend wie möglich aufnehmen.
          </Typography>
          <Typography mt={4}>Bitte stellen Sie sicher, dass Sie Informationen zu folgenden Themen zur Hand haben:</Typography>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Dämmung von Wänden
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Dämmung des Daches
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Kellerdämmung
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Außenfotos Ihrer Immobilie
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Grundrisse
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Schnitte (Seitenansichten)
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Baupläne (soweit vorhanden)
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline" }} />
            Energieausweis (soweit vorhanden)
          </Stack>
          <Typography mt={4}>Alle Eingaben werden zwischengespeichert und Sie können jederzeit zum letzten Bearbeitungsstand zurückkehren.</Typography>
          <Typography mt={4}>Sobald Sie bereit sind, klicken Sie auf die Schaltfläche unten, um zu beginnen. Herzlichen Dank!</Typography>
          <Stack direction='row' spacing={2} sx={{ mt: 8, justifyContent: "center" }}>
            <Button variant='contained' onClick={next}>
              Ich bin bereit!
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import German from "./lang/de.json";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
import ErrorBoundary from "./ErrorBoundary";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <IntlProvider locale={"de"} messages={German}>
          <App />
        </IntlProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { awsRumParams } from "./utils/params";

const client: { rum?: AwsRum } = {};
const RUM_DATAPLANE_URL = "https://dataplane.rum.eu-central-1.amazonaws.com";

export const retrieveRum = () => {
  if (client.rum) {
    return client.rum;
  }
  try {
    const params = awsRumParams();
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      endpoint: RUM_DATAPLANE_URL,
      telemetries: [
        "errors",
        "performance",
        [
          "http",
          {
            addXRayTraceIdHeader: true,
            urlsToExclude: [new RegExp(`${RUM_DATAPLANE_URL}/.+`)],
          },
        ],
      ],
      allowCookies: true,
      enableXRay: true,
      identityPoolId: params.identityPoolId,
    };

    if (params.applicationId) {
      client.rum = new AwsRum(params.applicationId, "1.0.0", "eu-central-1", config);
    }
  } catch (error) {
    console.log(error);
  }
  return client.rum;
};

import { useCallback, useContext, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { AuthContext } from "../Contexts";
import { retrieveRum } from "../AwsRum";

export const useAuthAxios = <ReturnType>() => {
  const [responseStatus, setStatus] = useState<number>();
  const [running, setRunning] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>();
  const [data, setData] = useState<ReturnType>();
  const { token, logout } = useContext(AuthContext);

  const authAxios = useCallback(
    async (req: AxiosRequestConfig) => {
      setError(undefined);
      setData(undefined);
      setRunning(true);
      try {
        const { data, status } = await axios.request({
          ...req,
          headers: {
            Authorization: `Bearer ${token?.id_token}`,
            ...req.headers,
          },
        });
        setStatus(status);
        setData(data);
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
        } else {
          retrieveRum()?.recordError(error);
        }
        setError(error);
        setData(undefined);
      } finally {
        setRunning(false);
      }
    },
    [logout, token],
  );

  return { authAxios, data, responseStatus, error, running, setError };
};

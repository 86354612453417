import { useState } from "react";
import { AuthError, AuthErrorType, userPool } from "./useAuth";
import { CognitoUser } from "amazon-cognito-identity-js";

export function useResetPasswordWithCode() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [resetSuccessful, setResetSuccessful] = useState<{ username: string } | undefined>(undefined);

  const resetPassword = async (username: string, verificationCode: string, password: string) => {
    setIsLoading(true);
    setResetSuccessful(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: username });
    await new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, password, {
        onSuccess: (success) => {
          setResetSuccessful({ username });
          resolve(success);
        },
        onFailure: (err) => {
          switch (err.name) {
            case "ExpiredCodeException":
              setError({ type: AuthErrorType.FAILED_RESET_PASSWORD_EXPIRED_CODE });
              break;
            case "LimitExceededException":
              setError({ type: AuthErrorType.FAILED_RESET_PASSWORD_LIMIT_EXCEEDED });
              break;
            case "CodeMismatchException":
              setError({ type: AuthErrorType.FAILED_RESET_PASSWORD_INCORRECT_CODE });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_RESETTING_PASSWORD });
          }
          return reject(err);
        },
      });
    })
      .catch((err) => {
        console.log(JSON.stringify(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return { isLoading, resetSuccessful, error, resetPassword };
}

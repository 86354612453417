import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { AuthError, AuthErrorType } from "./useAuth";
import { useState } from "react";
import { cognitoClientId, cognitoPoolId } from "../utils/params";

const userPool = new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() });

export default function useConfirmAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [confirmSuccess, setConfirmSuccess] = useState<{ username: string } | undefined>(undefined);

  const confirmAccount = async (email: string, code: string) => {
    setIsLoading(true);
    setConfirmSuccess(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: email });
    await new Promise((resolve, reject) => {
      user.confirmRegistration(code, false, (err, success) => {
        if (err) {
          switch (err.name) {
            case "CodeMismatchException":
              setError({ type: AuthErrorType.FAILED_CONFIRMING_ACCOUNT_WRONG_CODE });
              break;
            case "NotAuthorizedException":
              setError({ type: AuthErrorType.FAILED_CONFIRMING_ACCOUNT_POSSIBLY_ALREADY_CONFIRMED });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_CONFIRMING_ACCOUNT });
          }
          return reject(err);
        }
        setConfirmSuccess({ username: email });
        return resolve(success);
      });
    })
      .catch((err) => {
        console.log(JSON.stringify(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return { isLoading, confirmSuccess, error, confirmAccount };
}

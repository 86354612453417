import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../Contexts";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { ActionType, authErrorTypeToErrorText } from "../../hooks";
import { useNavigateToHomeByUserRole } from "../../hooks/useNavigateToHomeByUserRole";
import AuthContainer from "./AuthContainer";
import { ShowPasswordToggle } from "./ShowPasswordToggle";
import { isValidEmail } from "./emailValidator";

type LoginCredsState = {
  usernameError: boolean;
  passwordError: boolean;

  username: string;
  password: string;
};

export default function Signin() {
  const session = useContext(AuthContext);
  const [params] = useSearchParams();
  const [userCreds, setUserCreds] = useState<LoginCredsState>({
    username: params.get("username") ?? "",
    password: "",
    passwordError: false,
    usernameError: false,
  });
  const navigateToHome = useNavigateToHomeByUserRole();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const validateCredentials = (creds: LoginCredsState) => {
    return { usernameError: !isValidEmail(creds.username), passwordError: creds.password.length < 1 };
  };

  const onSubmitClick = () => {
    console.log("on submit click");
    const validateCreds = validateCredentials(userCreds);
    setUserCreds({ ...userCreds, ...validateCreds });
    if (validateCreds.passwordError || validateCreds.usernameError) {
      return;
    }
    session.login(userCreds.username, userCreds.password);
  };

  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log("username handler");
    setUserCreds({ ...userCreds, username: event.target.value.toLowerCase(), usernameError: false });
  };

  const passwordChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log("password handler");
    setUserCreds({ ...userCreds, password: event.target.value, passwordError: false });
  };

  useEffect(() => {
    if (session.user) {
      navigateToHome(session.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.user]);

  useEffect(() => {
    if (!session.actionNeeded) {
      return;
    }
    switch (session.actionNeeded.actionType) {
      case ActionType.PASSWORD_CHANGE_REQUIRED:
        navigate("/password-change-needed");
        break;
      case ActionType.CONFIRM_ACCOUNT_REQUIRED:
        navigate(`/confirm-account?username=${encodeURIComponent(session.actionNeeded.username)}`);
        break;
    }
  }, [session.actionNeeded, navigate, userCreds.password, userCreds.username]);

  function toggleShowPassword(): void {
    setShowPassword(!showPassword);
  }

  return (
    <AuthContainer>
      <Typography variant='h4' fontWeight={600}>
        Willkommen in deinem Cockpit
      </Typography>
      <Typography>
        Du hast noch keinen Account?{" "}
        <Link to={"/signup"} style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }} data-cy='novo-sign-up-lnk'>
          Account eröffnen
        </Link>
      </Typography>
      <Stack paddingTop={4}>
        <TextField
          label='E-Mail Adresse'
          variant='outlined'
          data-cy='email-input'
          id='email-input'
          value={userCreds.username}
          type='string'
          helperText={userCreds.usernameError ? "Bitte gib eine gültige E-Mail-Adresse ein." : undefined}
          error={!!userCreds.usernameError}
          onChange={emailChangeHandler}
          required={true}
        />
        <ShowPasswordToggle sx={{ paddingTop: 2, paddingBottom: 1 }} isShowing={showPassword} onClick={toggleShowPassword} />
        <TextField
          sx={{ paddingBottom: 2 }}
          label='Passwort'
          variant='outlined'
          data-cy='password-input'
          id='password-input'
          value={userCreds.password}
          type={showPassword ? "text" : "password"}
          onChange={passwordChangeHandler}
          error={userCreds.passwordError}
          helperText={userCreds.passwordError ? "Bitte gib dein vollständiges Passwort ein." : undefined}
          required={true}
        />
        <Link
          data-cy='forgot-password-lnk'
          to={userCreds.username ? `/forgot-password?username=${encodeURIComponent(userCreds.username)}` : "/forgot-password"}
          style={{ color: "#000", textDecorationColor: "none" }}
        >
          Passwort vergessen?
        </Link>
        <Box paddingTop={8} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='sign-in-btn'
            loading={session.isLoading}
            onClick={onSubmitClick}
            label='Anmelden'
            size='large'
            variant={"contained"}
            disabled={session.isLoading}
          />
        </Box>
        {session.error && (
          <Alert data-cy='change-error-alert' sx={{ marginTop: 2 }} severity='error'>
            {authErrorTypeToErrorText(session.error.type)}
          </Alert>
        )}
      </Stack>
    </AuthContainer>
  );
}

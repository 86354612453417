import { retrieveRum } from "../AwsRum";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export default function useDeleteConsumptionCertificate() {
  const { authAxios, running } = useAuthAxios();

  function deleteConsumptionCertificate(id: string) {
    const sendData = async () => {
      await authAxios({
        method: "DELETE",
        baseURL: `${apiEndpoint()}/consumption-certificate/${id}`,
      });
    };
    sendData().catch((e: unknown) => {
      console.error(e);
      retrieveRum()?.recordError(e);
    });
  }
  return { deleteConsumptionCertificate, deleting: running };
}

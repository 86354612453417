import { createContext } from "react";
import { AuthSession } from "./hooks/useAuth";

export const AuthContext = createContext<AuthSession>({
  isLoading: true,
  login: (): void => {},
  logout: () => {},
  updatePasswordOnRequireChange: () => {},
  clearActionNeeded: () => {},
});

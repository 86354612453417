import { Button, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import useAuth, { AuthSession } from "../../../../hooks/useAuth";

interface CCInviteClarificationProps {
  leadName?: string;
  leadEmail?: string;
}

const emailSubject = ({ leadName, leadEmail }: CCInviteClarificationProps): string => {
  if (leadName && leadEmail) {
    return `Neuer Energieausweisantrag für ${leadName} <${leadEmail}>`;
  }
  return "Neuer Energieausweisantrag";
};

const emailContent = (consultantName?: string): string => {
  return `Hallo NOVO-Team,

Ich möchte für einen Kunden mit einer besonderen Gebäudesituation einen Energieausweis anfordern.

Freundliche Grüße,
${consultantName}`;
};

export const ccEmailHref = (session: AuthSession, props: CCInviteClarificationProps) => {
  return `mailto:hi@buildingnovo.com?subject=${emailSubject(props)}&body=${encodeURIComponent(emailContent(session.user?.name))}`;
};

export const CCInviteClarification = (props: CCInviteClarificationProps) => {
  const session = useAuth();

  return (
    <>
      <Typography>
        Laut GEG dürfen Verbrauchsausweise für Wohngebäude mit bis zu 4 Einheiten erstellt werden, wenn das Baujahr nach 1977 liegt oder sie die
        Wärmeschutzverordnung von 1977 erfüllen. Für alle anderen Anfragen, z.B. Bedarfsausweise oder Verbrauchausweise für Mehrfamilienhäuser, sende uns bitte
        eine E-Mail.
      </Typography>
      <Button variant='contained' href={ccEmailHref(session, props)} sx={{ alignSelf: "flex-start" }}>
        <EmailIcon sx={{ verticalAlign: "middle", mr: 2 }} />
        E-Mail Senden
      </Button>
    </>
  );
};

import { DeploymentEnv } from "../types/types";

export const apiEndpoint = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return process.env.REACT_APP_CUSTOM_SERVER_URL ? process.env.REACT_APP_CUSTOM_SERVER_URL : "https://api-dev.novo.eco";
    case "app-stg.novo.eco":
      return "https://api-stg.novo.eco";
    case "app.novo.eco":
      return "https://api.novo.eco";
    case "app-dev-gui.novo.eco":
      return "https://api-dev-gui.novo.eco";
    default:
      throw new Error("hostname not recognised");
  }
};

export const awsRumParams = () => {
  switch (window.location.hostname) {
    case "app-dev-gui.novo.eco":
      return { applicationId: "8aae631a-c3b2-4039-9005-9f4fe534ec05", identityPoolId: "eu-central-1:f89514ab-da84-4802-b800-67529355587b" };
    case "app-dev.novo.eco":
      return { applicationId: "92dc3d15-160e-4317-9111-c22a6b1c9ff9", identityPoolId: "eu-central-1:c25ee262-c5ae-47f7-939e-9c9a8d04e865" };
    case "app-stg.novo.eco":
      return { applicationId: "1e38d28d-9c11-412b-9141-b6a194000093", identityPoolId: "eu-central-1:31e7655b-4d64-47f3-9271-dde01fe54d39" };
    case "app.novo.eco":
      return { applicationId: "bf9280da-9a0c-49d3-a964-f2407c5a0455", identityPoolId: "eu-central-1:914b6b79-b547-4a8f-8e5f-fde7cd875584" };
    default:
      return {};
  }
};

export const deploymentEnv = (): DeploymentEnv => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
      return DeploymentEnv.Local;
    case "app-dev-gui.novo.eco":
    case "app-dev.novo.eco":
      return DeploymentEnv.Dev;
    case "app-stg.novo.eco":
      return DeploymentEnv.Stg;
    case "app.novo.eco":
      return DeploymentEnv.Prod;
    default:
      return DeploymentEnv.Embedded;
  }
};

export const stripeKey = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev-gui.novo.eco":
    case "app-dev.novo.eco":
    case "app-stg.novo.eco":
      return "pk_test_51M6wvZLRBLaJ8zNCnBD26IiZZQvLSiIzTej3t5ITo84sOTavek6AZDoY7082Bng93Ag5LhR3zDfWbr13DWlIAWsW00jlOfuoPb";
    case "app.novo.eco":
      return "pk_live_51M6wvZLRBLaJ8zNCIXc4x19Cwrc7ILrN5DfGz85klwkmDntj4tqXoUSyFvUyowmnyVYcIISUZj7NKNN2LAcEKdse00g86HsoMF";
    default:
      throw new Error("hostname not recognised");
  }
};

export type Env = "dev" | "stg" | "prod";

export const env = (): Env | undefined => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
    case "app-dev-gui.novo.eco":
      return "dev";
    case "app-stg.novo.eco":
      return "stg";
    case "app.novo.eco":
      return "prod";
    default:
      return undefined;
  }
};

export const bubbleCertificateEndpointRedirect = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev-gui.novo.eco":
    case "app-dev.novo.eco":
    case "app-stg.novo.eco":
      return "https://novo.eco/version-test/energieausweis";
    case "app.novo.eco":
      return "https://novo.eco/energieausweis";
    default:
      throw new Error("bubble hostname not recognised");
  }
};

export const cognitoClientId = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return process.env.REACT_APP_CUSTOM_AUTH_IDP ? JSON.parse(process.env.REACT_APP_CUSTOM_AUTH_IDP).clientId : "282tqvsjj66idq4igbdqslu9pb";
    case "app-stg.novo.eco":
      return "2kr3rri2k4jl0gorraa2feha2q";
    case "app.novo.eco":
      return "7i4r7dn6d62em7qdmg5ipiheii";
    case "app-dev-gui.novo.eco":
      return "fpqg43f9pf9j02huadmkdneto";
    default:
      throw new Error("hostname not recognised");
  }
};

export const cognitoPoolId = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return process.env.REACT_APP_CUSTOM_AUTH_IDP ? JSON.parse(process.env.REACT_APP_CUSTOM_AUTH_IDP).poolId : "eu-central-1_kbc77Yl3M";
    case "app-stg.novo.eco":
      return "eu-central-1_ZIHPkxqrO";
    case "app.novo.eco":
      return "eu-central-1_o0g7ETcpc";
    case "app-dev-gui.novo.eco":
      return "eu-central-1_swktwEcPB";
    default:
      throw new Error("hostname not recognised");
  }
};

export const embeddablePage = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
      return "http://localhost:3000/foerder-welt-embedded";
    default:
      return `https://${window.location.hostname}/foerder-welt-embedded`;
  }
};
